function useEleDirective(vm:any) {
	if (vm) {
		vm.directive("active", {
			mounted(el, bingding) {
				updatedEleParams(bingding);
			},
			updated(el, bingding) {
				updatedEleParams(bingding);
			},
		});
	}
}

function updatedEleParams(bingding:any): void {
	let id = bingding.instance.$.attrs.id || bingding.instance.$.props.id;

	let _params = bingding.value;
	if (_params) {
		const apps = bingding.instance.$.provides["apps"];
		const paramsStore = apps?.paramsStore;
		const pageId = bingding.instance.$.attrs['page-id'] || apps?.projectStore.currentPage;
		let eleonentParams = paramsStore.eleParams;
		if (eleonentParams) {
			paramsStore.addEleParam(pageId, id, _params);
		}
	}
}

export { useEleDirective };