import { deepClone, guid, isObject, getParamsValue, isString, isArray } from "./index";

type IStringObject = Record<string, any>;

//属性配置转换为值
export function changeAttr(attr: any): IStringObject {
  let group = {} as IStringObject;
  let attrCopy = deepClone(attr); //复制属性配置，防止污染
  attrCopy?.map((item) => {
    if (item.children && item.children.length > 0) {
      if (item.type == "group") {
        group[item.name] = changeGroup(item.children);
      } else {
        group[item.name] = changeAttr(item.children);
      }
    } else if (item.name) {
      group[item.name] = dealWithSpecialType(item);
    }
  });
  return group;
}

//属性组转化
function changeGroup(arr: any): any[] {
  let groupArr = [] as any[];
  arr.map((items) => {
    if (Object.prototype.toString.call(items) == "[object Array]") {
      let group = {} as IStringObject;
      items.map((item) => {
        if (item.type == "group") {
          group[item.name] = changeGroup(item.children);
        } else if (item.type == "list") {
          group[item.name] = changeAttr(item.children);
        } else {
          group[item.name] = dealWithSpecialType(item);
        }
      });
      groupArr.push(group);
    }
  });
  return groupArr;
}

//处理属性配置的特殊类型
export function dealWithSpecialType(attrItem: any): any {
  let rst;
  if (attrItem.type == "basicsetting") {
    rst = {
      width: attrItem.setting.w,
      height: attrItem.setting.h,
      left: 0,
      top: 0,
      zIndex: 0,
      isShow: true,
      changeUnit: attrItem.setting.changeUnit || false,
    };
  } else if (attrItem.type == "input_number") {
    rst = attrItem.suffix ? Number(attrItem.value) + attrItem.suffix : Number(attrItem.value);
  } else if (attrItem.value == "" && attrItem.type == "guid") {
    rst = guid();
  } else {
    rst = attrItem.value;
  }
  return rst;
}

//转换属性中动态变量
export function getElementAttrFormData(initAttrValue: any, paramsStore: any): IStringObject {
  let target = deepClone(initAttrValue);
  if (isObject(target)) {
    Object.keys(target).map((item) => {
      if (isString(target[item])) {
        target[item] = getParamsValue(paramsStore, target[item]);
      } else {
        target[item] = getElementAttrFormData(target[item], paramsStore);
      }
    });
  } else if (isArray(target)) {
    target.map((value, i) => {
      target[i] = getElementAttrFormData(value, paramsStore);
    });
  }
  return target;
}
