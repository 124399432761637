/*
 * @Date: 2023-08-08 17:15:24
 * @LastEditors: zhutj
 * @LastEditTime: 2024-08-08 15:38:02
 */
import { getParamsValueByKey, getAllParams } from "./params";

const ProcessStatus = {
  INIT: -1, //初始化状态
  READY: -2, //处理中状态
  PROCESSING: 0, //处理中状态
  COMPLTED: 1, //处理完成状态
};

// interface schedulerType {
//     instance: any;
// }

class dataSrcScheduler {
  // public instance : any;

  constructor() {
    this.init();
  }

  private dataSrcPool = [];
  private dataSources = [];
  private association = {};
  private completedDataSrc = [];
  private completedAsyncRequest = [];
  private scheduleEnded = false;

  // 初始化
  init() {
    // this.instance = null;
    // this.compId = null;
    this.dataSrcPool = []; // 处理器中数据源列表
    this.dataSources = []; // 实际数据源列表
    this.association = {}; //数据源关联关系
    // this.calScripts = []; // 计算脚本数据源列表
    this.completedDataSrc = []; // 已经执行完的数据源
    this.completedAsyncRequest = []; // 已经执行完的数据源接口（config、customize）
    this.scheduleEnded = false;
  }

  // 获取单例实例
  static getInstance() {
    if (!this["instance"]) {
      this["instance"] = new dataSrcScheduler();
    }
    return this["instance"];
  }

  // 添加数据源
  public addDataSrcNode(dataSrcNodes, allDataSrc, nodeId, callBack) {
    if (!dataSrcNodes) return;

    this.dataSources = allDataSrc;

    const sourceItem = {
      compId: nodeId,
      ...dataSrcNodes,
      processStatus: ProcessStatus.INIT,
      callBack,
    };
    let index = this.dataSrcPool.findIndex((item) => item.compId == nodeId);
    if (index > -1) {
      this.dataSrcPool[index] = sourceItem;
    } else {
      this.dataSrcPool.push(sourceItem);
    }

    //TODO:自定义数据源判断和静态参数判断

    this.handleDynamicDataSrc();
  }

  //处理动态依赖数据节点
  private handleDynamicDataSrc() {
    let dataSrcPool;
    dataSrcPool = this.dataSrcPool;

    let dynamicDataSrc = dataSrcPool.filter(
      (node) =>
        node.processStatus !== ProcessStatus.COMPLTED &&
        node.processStatus !== ProcessStatus.PROCESSING
    );

    let nodeIds = [];
    dynamicDataSrc &&
      dynamicDataSrc.forEach((node) => {
        // 只有当前面的参数isParamsReady都为true时才能将isParamsReady设置为true，否则为false
        let isParamsReady = true;
        node.params &&
          node.params.forEach((param) => {
            if (param.value && typeof param.value == "string") {
              if (param.value.includes("#{")) {
                const keysArr = getAllParams(param.value);
                keysArr &&
                  keysArr.forEach((item) => {
                    const keys = getParamsValueByKey(item);
                    if (
                      !keys["type"] &&
                      keys["valType"] == "dynamic" &&
                      !this.completedDataSrc.some(
                        (item) =>
                          item.compId == keys["id"] &&
                          item.processStatus === ProcessStatus.COMPLTED
                      ) &&
                      this.dataSources[keys["id"]].definiteMethod != "customize"
                    ) {
                      isParamsReady = false;
                      (this.association[node.compId] ||
                        (this.association[node.compId] = {}))[keys["id"]] =
                        false;
                    }
                  });
              }
            }
          });

        if (isParamsReady) {
          node.processStatus = ProcessStatus.READY;
          if (
            !this.completedDataSrc.some((item) => item.compId == node.compId)
          ) {
            this.completedDataSrc.push(node);
          }
          if (!nodeIds.includes(node.compId)) {
            nodeIds.push(node);
          }
        }
      });

    nodeIds.forEach((node) => {
      if (node.processStatus == ProcessStatus.READY) {
        dataSrcPool.forEach((item) => {
          if (item.compId === node.compId) {
            item.processStatus = ProcessStatus.PROCESSING;
          }
        });
        node.callBack();
      }
    });
  }

  // 动态数据请求回调
  public dynamicDataSrcCb(id) {
    let node = this.completedDataSrc.find((node) => node.compId === id);
    let falg = false;
    if (node) {
      node.processStatus = ProcessStatus.COMPLTED; //数据源状态设置为已加载
      Object.keys(this.association).forEach((key) => {
        if (
          this.association[key].hasOwnProperty(id) &&
          !this.association[key][id]
        ) {
          this.association[key][id] = true;
          if (
            Object.keys(this.association[key]).every(
              (keys) => this.association[key][keys]
            )
          ) {
            this.handleDynamicDataSrc();
            falg = true;
          }
        }
      });
    }
    if (falg) return;
    if (
      this.completedDataSrc.every(
        (node) => node.processStatus === ProcessStatus.COMPLTED
      )
    ) {
      if (
        this.dataSrcPool.some(
          (node) => node.processStatus !== ProcessStatus.COMPLTED
        )
      ) {
        // console.log("继续下一轮动态节点请求");
        this.handleDynamicDataSrc();
      } else {
        // console.log('完成调用链');
        this.handleDynamicDataSrc();
        this.scheduleEnded = true;
      }
    } else if (
      this.dataSrcPool.some(
        (node) =>
          node.processStatus !== ProcessStatus.COMPLTED &&
          node.processStatus !== ProcessStatus.PROCESSING
      )
    ) {
      let nextFalg = false;
      Object.keys(this.association).forEach((key) => {
        let falseArr = Object.keys(this.association[key]).filter(
          (keys) => !this.association[key][keys]
        );
        if (falseArr.length) {
          falseArr.forEach((item) => {
            if (
              this.dataSources[item] &&
              this.dataSources[item].definiteMethod == "customize"
            ) {
              this.association[key][item] = true;
              nextFalg = true;
            }
          });
        }
      });
      if (nextFalg) this.handleDynamicDataSrc();
    } else {
      if (
        !this.dataSrcPool.some(
          (node) => node.processStatus !== ProcessStatus.COMPLTED
        )
      ) {
        // console.log("继续下一轮动态节点请求");
        this.handleDynamicDataSrc();
      }
    }
  }
}

export { dataSrcScheduler };
