<template>
  <div v-if="loading" class="page-preload">
    <el-skeleton v-if="pageAttr?.loadType == LoadTypeEnum.SKELETON" animated :loading="loading">
      <template #template>
        <el-skeleton-item
          v-for="item in pageData['elements']"
          :key="item.id"
          variant="text"
          :style="getCompStyle(item.initValue || item.props)"
        ></el-skeleton-item>
      </template>
    </el-skeleton>
    <!-- LoadTypeEnum.MASK -->
    <div
      v-else class="loading" :style="{
        'color': pageAttr?.loadColor
      }"
    >
      {{ pageAttr?.loadText }}
    </div>
  </div>
</template>
<script lang="ts" setup>
import { toRefs,computed } from "vue";
import { ElSkeleton, ElSkeletonItem } from "element-plus";
import {LoadTypeEnum} from 'fusion-core';
const props = defineProps({
  loading: Boolean,
  pageData: {
    type: Object,
    default: () => {
      return {
        elements: [],
      };
    },
  },
});
const { loading, pageData, } = toRefs(props);
const pageAttr = computed(()=>pageData.value.attribute); //获取页面配置信息

//骨架屏占位计算
function getCompStyle(data: any): {
  width: string;
  height: string;
  top: string;
  left: string;
} {
  return {
    width: data.baseAttr.width + "px",
    height: data.baseAttr.height + "px",
    top: data.baseAttr.top + "px",
    left: data.baseAttr.left + "px",
  };
}
</script>
<style scoped>
.page-preload{
  width: 100%;
  height: 100%;
}
.loading{
width: 100%;
height: 100%;
text-align: center;
font-size: 30px;
background-color: rgba(33, 33, 33, 0.5);
}
</style>
<style>
.el-skeleton__item {
  position: absolute;
  background-color: rgba(33, 33, 33, 0.5);
}
</style>
