import type {
  IPage,
  IProject,
  ICustomJS
} from "fusion-core";
import { Project } from "fusion-core";
import { defineStore } from "pinia";

export const p = Project.create();

export const useProjectStore = defineStore({
  id: "project",
  state: () => {
    return {
      project: p.getRecord() as IProject, //工程数据
      appId: "", //应用ID
      projectId: "", //项目ID
      elementSource: "", // 当前拖拽的元素来源 newElement: 新生成组件；oldElement: 已生成组件
      currentElementId: "", //当前组件的id
      currentPageId: "", //当前页面索引
      customJSArr: [] as ICustomJS[], //自定义脚本池
    };
  },
  getters: {
    //从工程信息里获取当前页面
    currentPage(): IPage {
      return this.getCurrentPageById(this.currentPageId);
    },

    // 获取当前页面里的组件序号组
    currentPageIndexManager() {
      return this.currentPage?.indexManager || {};
    },
  },
  actions: {
    //设置当前页面id
    setCurrentPageId(id:string){
      this.currentPageId = id;
    },

    //获取当前页面信息
    getCurrentPageById(id: string) {
      return this.project.pages.find((item) => item.id == id);
    },

    // 通过id查找元素
    getElementById(id: string) {
      return p.getPageById(this.currentPageId)?.getElementById(id);
    },

   //获取页面事件
     getPageEventsById(id: string) {
      const pageInfo = this.project.pages.find((item) => item.id == id);
      return pageInfo.events;
    },

    //设置页面信息
    setPageInfo(pageInfo: IPage) {
      const pageIndex = this.project.pages.findIndex(
        (item) => item.id === pageInfo?.id
      );
      if (pageInfo && pageIndex === -1) {
        // this.project.pages.push(pageInfo);
        p.addPage(pageInfo);
      };
      this.project = p.getRecord();
    },

    //设置自定义脚本
    setCustomJsArr(customJSArr:ICustomJS[]):void{
      this.customJSArr = customJSArr;
    },

   //根据脚本code获取脚本信息
    getCustomJSByCode(code:string){
     let customJs = this.customJSArr.filter(v=>v.code == code);
     if (customJs.length > 0){
      return customJs[0].func;
     } else {
      console.warn(`未找到${code}对应的脚本!!!`);
      return "";
     }
    },

    //保存父页面id
     setParentPageId(currentId: string,id: string): void {
      p.getPageById(currentId)!.setParentPageId(id);
      this.project = p.getRecord();
    },

    //获得父页面id
    getParentPageId(currentId: string): string{
      return p.getPageById(currentId)!.pPageId;
    },
  },
});
