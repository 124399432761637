/*
 * @Author: zhutj
 * @Date: 2024-08-08 11:45:20
 * @LastEditors: zhutj
 * @LastEditTime: 2024-08-08 15:15:25
 * @Description:
 * @FilePath: \s_pageeditorv3\工作库\05.项目编码阶段\源代码\packages\core\src\utils\systemGlobalFuncs.ts
 */
export const systemFuncs = [
  {
    id: "systemFunc",
    label: "系统函数",
    keyName: "",
    pid: "-1",
    type: "builtIn",
    value: "",
  },
  {
    id: "systemTimes",
    label: "系统时间",
    keyName: "",
    pid: "systemFunc",
    type: "builtIn",
    value: "",
  },
  {
    id: "getDate",
    label: "当前年月日",
    keyName: "",
    pid: "systemTimes",
    type: "SCRIPT",
    func: `const currentDate = new Date(); return formatDate(currentDate) `,
    value: "",
    dependencies: { formatDate },
  },

  {
    id: "getDatetime",
    label: "当前年月日时",
    keyName: "",
    pid: "systemTimes",
    type: "SCRIPT",
    func: `const currentDate = new Date(); return formatDate(currentDate,'yyyy/MM/dd hh') `,
    value: "",
    dependencies: { formatDate },
  },
  {
    id: "getDateMins",
    label: "当前年月日时分",
    keyName: "",
    pid: "systemTimes",
    type: "SCRIPT",
    func: `const currentDate = new Date(); return formatDate(currentDate,'yyyy/MM/dd hh:mm') `,
    value: "",
    dependencies: { formatDate },
  },
  {
    id: "getDateSec",
    label: "当前年月日时分秒",
    keyName: "",
    pid: "systemTimes",
    type: "SCRIPT",
    func: `const currentDate = new Date(); return formatDate(currentDate,'yyyy/MM/dd hh:mm:ss') `,
    value: "",
    dependencies: { formatDate },
  },
];

function formatDate(date, format = "yyyy-MM-dd") {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false, // 确保使用24小时制
  };

  // 创建日期时间格式器
  const formatter = new Intl.DateTimeFormat("en-US", options);

  // 根据提供的格式替换占位符
  let formattedDate = format.replace(/yyyy|MM|dd|hh|mm|ss/g, (match) => {
    const parts = formatter.formatToParts(date);
    switch (match) {
      case "yyyy":
        return parts.find((part) => part.type === "year").value;
      case "MM":
        return parts.find((part) => part.type === "month").value;
      case "dd":
        return parts.find((part) => part.type === "day").value;
      case "hh":
        return parts.find((part) => part.type === "hour").value;
      case "mm":
        return parts.find((part) => part.type === "minute").value;
      case "ss":
        return parts.find((part) => part.type === "second").value;
      default:
        return match;
    }
  });

  // 将分隔符替换为短横线或冒号
  formattedDate = formattedDate.replace(/\/|\//g, "-").replace(/:/g, ":");

  return formattedDate;
}
