/*
 * @Descripttion:组件执行动画
 * @Author: zhangsy
 * @Date: 2023-10-27 10:18:28
 * @LastEditors: zhangsy
 * @LastEditTime: 2023-10-28 16:13:35
 */
import { IAnimationGroup,IAnimationElement } from "fusion-core";
import { ComputedRef} from "vue";

export function animateHook() {
    //动画组合并
    function mergeAnimateData(animationAttr:ComputedRef<IAnimationGroup>) {
      return [...animationAttr.value.inAnimations, ...animationAttr.value.stressAnimations, ...animationAttr.value.outAnimations];
  }

    //动画执行
  function playAnimation(el: HTMLElement, animation: IAnimationElement) {
      return new Promise<void>(resolve => {
      el.style.animationName = animation.name;
      el.style.animationDuration = `${animation.duration}s`;

      // 如果是循环播放就将循环次数置为1，这样有效避免编辑时因为预览循环播放组件播放动画无法触发animationend来暂停组件动画
      el.style.animationIterationCount = animation.infinite ? "infinite" : animation.interationCount.toString();
      el.style.animationDelay = `${animation.delay}s`;
      el.style.animationFillMode = 'both';
      let resolveAnimation = function() {
      el.removeEventListener("animationend", resolveAnimation, false);
      el.addEventListener("animationcancel", resolveAnimation, false);
      resolve();
      };
      el.addEventListener("animationend", resolveAnimation, false);//动画结束
      el.addEventListener("animationcancel", resolveAnimation, false); //动画中断
      });
    }

    //绑定动画
  async function bindAnimate(el: HTMLElement, animationArr: IAnimationElement[]) {
      let cssText = el.style ? el.style.cssText : "";
      el.classList.add("animate__animated");
      for (let i = 0, len = animationArr.length; i < len; i++) {
        await playAnimation(el,animationArr[i])
      }

      //恢复原状
      el.classList.remove("animate__animated");
      el.style.cssText = cssText.replace(/animation.*;/g, "");
      el.setAttribute("style", cssText);
  }

    return {
      mergeAnimateData,
      bindAnimate,
    }
}