//全局变量配置文件
function useGlobParams() {
    const globalEmitter = Symbol('globalEmitter');
    const deployConfig = Symbol('deployConfig');
    const RequestInstance = Symbol('RequestInstance');
    const iconfontJson = Symbol('iconfontJson');
    const resolutionPower = Symbol('resolutionPower');
    const ATUtils = Symbol('ATUtils');

     const _USECONFIG = {
         globalEmitter: globalEmitter,
         deployConfig: deployConfig,
         requestInstance: RequestInstance,
         iconfontJson: iconfontJson,
         resolutionPower: resolutionPower,
         ATUtils,

     }
     return _USECONFIG;
 }
  export const _USECONFIG = useGlobParams();
