<template>
  <component
    :is="isReleased ? elementsInstance[elementName].compInfo.render : elementName"
    v-show="isElementShow"
    :id="elementData.id"
    :class="{'fusion-element':true,'remove-event':!isElementShow || elementName.includes('FscAnimationCurtains')}"
    :element-data="elementInfo"
    :page-id="pageId"
    :p-page-id="pPageId"
    :is-comp="true"
    :is-element-show="isElementShow"
    :is-p-show="isPShow"
    @set-show-state="setShowState"
  >
    <slot :is-p-show="isElementShow"></slot>
  </component>
</template>

<script setup lang="ts">
import { toRefs,ref, computed,inject, onMounted, watchEffect, toRaw, watch } from "vue";
import { IElement, getConditionResult, getMaterialInitValue, mergeDeep, deepClone} from "fusion-core";

const props = defineProps<{
  elementData: IElement;
  elementsInstance?: any;
  pageId:String;
  pPageId?:String;
  isPShow:boolean;
}>();
const pageId = toRaw(props.pageId),pPageId = toRaw(props.pPageId);
const emits = defineEmits(['change-is-element-show']);
const { elementsInstance, } = toRefs(props);
const apps = inject('apps') as Record<string, any>;
const paramsStore = apps.paramsStore;
const isPreview = apps?.editorStore?.isPreview ?? false;//是否为预览
const isReleased = apps?.genStore?.isReleased ?? false; //是否为衍生环境
const isElementShow = ref(true);
const elementData = computed(() => props.elementData);

// 组件名称
const elementName = computed(() => elementData.value.code);

let eleInitValue = '';

watch(elementName, () => {
  eleInitValue = isReleased ? elementsInstance?.value[elementName.value].initValue : getMaterialInitValue(elementData.value.code);
}, {
  deep: true,
  immediate: true,
})

//此处监听为了编辑器下切花组件可见状态可看到效果
watchEffect(()=>{
  let isShow = true;
    const eleAttr = elementData.value.props.baseAttr || eleInitValue.baseAttr;//获取组件基础属性配置
    if (!eleAttr.isCondition) {
      isShow = !!eleAttr.isShow;
    }
    isElementShow.value = isShow;
    emits('change-is-element-show',isShow);
});

onMounted(()=>{
  const eleAttr = elementData.value.props.baseAttr || eleInitValue.baseAttr;//获取组件基础属性配置
  if ((isReleased || isPreview) && eleAttr.isCondition){
    let isTure = getConditionResult(eleAttr.conditions || [],paramsStore);
    isElementShow.value = eleAttr.isShowCondition ? isTure : !isTure;
    emits('change-is-element-show',isElementShow.value);
  }
});

const elementInfo = computed(() => {
  let initValue = mergeDeep(deepClone(eleInitValue),elementData.value.props);
  return {...elementData.value,initValue: ref(initValue).value,}
});

//控制组件的显示与隐藏
function setShowState(params:Record<string,any>):void{
  if (params.isShow && Number(params.isShow)){
    isElementShow.value = true;
  } else {
    isElementShow.value = false;
  }
  emits('change-is-element-show',isElementShow.value);
}
</script>
<style scoped>
.component{
  width: 100%;
  height: 100%;
}
</style>