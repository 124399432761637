//自定义脚本相关公共方法
import { ElMessage } from "element-plus";

//自定义脚本执行方法
export function runCustomJs(
  params: Object,
  func: any,
  data?: any,
  dependencies?: Object
): boolean {
  let isExeSuccess = true;
  let res = null; // 适配数据源中自定义脚本，需要脚本抛出结果
  try {
    let customJsFuncParams = [];
    let customJsFuncArgs = [];

    if (dependencies) {
      customJsFuncParams = customJsFuncParams.concat(Object.keys(dependencies));
      customJsFuncArgs = customJsFuncArgs.concat(Object.values(dependencies));
    }

    let customJsfunc = new Function(
      "paramsObj",
      "dataRes",
      ...customJsFuncParams,
      `"use strict";\n${func}`
    );

    if (params) {
      res = customJsfunc(params, data, ...customJsFuncArgs);
    } else {
      res = customJsfunc(undefined, data, ...customJsFuncArgs);
    }

    isExeSuccess = res;
  } catch (error) {
    isExeSuccess = false;
    console.log("脚本执行失败！");
    ElMessage.warning("脚本执行失败！");
  }
  return res || isExeSuccess;
}
