import { defineStore } from "pinia";
import { setSessionItem, getSessionItem } from "fusion-core";

export const useGenStore = defineStore({
  id: "genPool",
  state: () => {
    return {
      routerKeepAlive: {} as Record<string, boolean>, //页面路由保持信息记录
      loadingCount: 0, //页面加载计数器
      isReleased: true, //标记为衍生环境
      isAgpApplistLoaded: getSessionItem("isAgpApplistLoaded"), //agp平台专用参数
    };
  },
  getters: {},
  actions: {
    //设置路由保持信息
    setRouterKeepAlive(pageCode: string, isKeepAlive: boolean) {
      this.routerKeepAlive[pageCode] = isKeepAlive;
    },

    //获取路由保持信息
    getRouterKeepAlive(pageCode: string) {
      let isKeepAlive = this.routerKeepAlive[pageCode];
      return isKeepAlive ?? false;
    },

    //增加加载计数器
    addLoadingCount() {
      this.loadingCount++;
    },

    //减少加载计数器
    reduceLoadingCount() {
      this.loadingCount--;
    },
    setIsAgpApplistLoaded(isLoaded) {
      this.isAgpApplistLoaded = isLoaded;
      setSessionItem("isAgpApplistLoaded", isLoaded);
    },
  },
});
