/*
 * @Descripttion:免登录前端拦截
 * @Author: zhutj
 * @Date: 2023-11-28 11:36:50
 * @LastEditors: zhutj
 * @LastEditTime: 2024-08-29 16:13:37
 */
import type { Router } from "vue-router";
import { ElMessage } from "element-plus";
import { setCookie,getCookie, setLoginInfo, getHeadsObject } from "fusion-core";

export function createNoLoginGuard(router: Router) {
  const requestHeader = (window as any).BaseConfig.requestHeader;
  delete sessionStorage.isNoLoginToken; //删除免登录token标记
  router.beforeEach(async (to, from, next) => {
    // 是否同一路由
    if (to.path == from.path && !to.matched.length) {
      return;
    } else if (to.path && to.path !== "/login") {
      //  是否免登录页,如果是需要判断当前有无token,没有的话，需要取临时token
      if (to.meta.noLogin) {
        if (!getAuth()) {
          fetch("./config/noLoginToken.json")
            .then(function (response) {
              return response.json();
            })
            .then(function (data) {
              setLoginInfo({
                [requestHeader.TOKEN]: data.token,
                [requestHeader.OPENID]: data.openId,
                isNoLoginToken: 1,
              });
              setCookie("isNoLoginToken",1);
            });
        }
        next();
        return;
      } else {
        if (getAuth()) {
          next();
          return;
        } else {
          const routerList = router.options.routes;
          const loginPage = routerList.find((item) => item.path == "/login");

          ElMessage({
            message: "用户未登录，页面跳转中...",
            type: "error",
            onClose: () => {
              if (loginPage) {
                next({
                  path: "/login",
                  query: Object.assign(to.query, {
                    redirect_url: window.location.href,
                  }),
                });
              } else {
                next({
                  path: "/",
                  query: Object.assign(to.query, {
                    redirect_url: window.location.href,
                  }),
                });
              }
            },
          });
        }
      }
    } else if (to.path) {
      next();
    }
  });
}

function getAuth() {
  const requestHeader = (window as any).BaseConfig.requestHeader;
  let isNoLoginToken = getCookie("isNoLoginToken");
  let headObj = getHeadsObject([requestHeader.TOKEN, requestHeader.OPENID]);
  if (
    !isNoLoginToken &&
    headObj &&
    headObj[requestHeader.TOKEN] &&
    headObj[requestHeader.OPENID]
  ) {
    return true;
  } else {
    return false;
  }
}
