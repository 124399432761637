import { getHeadsObject } from "fusion-core";

export default function getHeader(header) {
  const requestHeader = (window as any).BaseConfig.requestHeader;
  const deployConfig = (window as any).deployConfig;
  let headObj: any = getHeadsObject([
    requestHeader.TOKEN,
    requestHeader.OPENID,
    requestHeader.APPID,
  ]);
  !headObj[requestHeader.APPID] && (headObj[requestHeader.APPID] = deployConfig.appId);
  return Object.assign(header, headObj);
}
