/*
 * @Descripttion:设置全局window挂载变量类
 * @Author: zhutj
 * @Date: 2023-12-13 15:48:14
 * @LastEditors: zhutj
 * @LastEditTime: 2023-12-16 17:09:13
 */
// import { globalW } from "./getGlobalThis";
import { _USECONFIG } from "./globalParamsConfig";
export class GlobalParamsInstance {
    private static _instance: GlobalParamsInstance;

    public iconfontJson: any = {};
    public resolutionPower: any = {};

  constructor() {
    // 初始化_fusion字段
    this.init();
  }
  protected init() {
    // globalW._FUSION = {};
      this.iconfontJson = {};
      this.resolutionPower = {};
  }

  /**
   * description 根据key获取window中值
   * @author zhutj 2023年12月13日 16:01:20
   * @param param
   * @return param
   * */
  public getGlobalParams(key: string) {
    return this[_USECONFIG[key]];
  }

  /**
   * description 根据key设置window中值
   * @author zhutj 2023年12月13日 16:01:20
   * @param param
   * @return param
   * */
  public setGlobalParams(key: string, val: any) {
    if (_USECONFIG[key] == undefined) {
      alert("非法字段，请去全局配置进行有效设置");
      return;
    }
    this[_USECONFIG[key]] = val;
  }

  /**
   * 创建唯一实例（单例模式）
   */
  public static getInstance(): GlobalParamsInstance {
    // 如果 instance 是一个实例 直接返回，  如果不是 实例化后返回
    if (this._instance) {
      return this._instance;
    } else {
      this._instance = new GlobalParamsInstance();
      return this._instance;
    }
  }
}
