/*
 * @Descripttion:参数选择器方法
 * @Author: zhangsy
 * @ Modified by: zhangsy
 * @ Modified time: 2024-07-19 16:31:37
 * @LastEditTime: 2024-08-08 14:53:03
 */

import { SelectParamsType } from "../enums/index";
import { IGlobalParam, IEventsEleParams } from "../interface/project/index";
import {
  isString,
  isParamsString,
  isJsonArrayString,
  isCustomJsString,
} from "./is";
import { getUrlParam } from "./utils";
import { getSessionItem, getCookie } from "./handleATUtils";
import { runCustomJs } from "./customJs";

//解析组件参数值的方法
export function getParamsValue(paramsStore: any, paramString: any): string {
  let result = "" as any;
  let arr = [];
  let paramsType = getParamsTypeByString(paramString);
  if (paramsType === SelectParamsType.PARAMS) {
    arr.push(paramString);
  } else if (paramsType === SelectParamsType.MULTI) {
    arr = JSON.parse(paramString);
  } else if (paramsType === SelectParamsType.JSONTREE) {
    return treeToJson(paramsStore, paramString);
  } else if (
    paramsType === SelectParamsType.TEXT ||
    paramsType === SelectParamsType.NORMAL
  ) {
    return paramString;
  }
  if (paramsType === SelectParamsType.JSCODE) {
    let func = getCustomJsParams(paramsStore, paramString);
    result = runCustomJs("", func);
  } else {
    arr.forEach((item) => {
      if (isParamsString(item)) {
        const currentParams = getParamsItemByString(paramsStore, item);
        if (paramsType == SelectParamsType.MULTI) {
          result += currentParams?.value;
        } else {
          result = currentParams?.value;
        }
      } else {
        result += item;
      }
    });
  }

  return result;
}

function getCustomJsParams(paramsStore: any, paramString: string) {
  let singlePattern = /'#{.*?\}'/g;
  let doublePattern = /"#{.*?\}"/g;
  let singlematcherArr = paramString.match(singlePattern);
  let doublematcherArr = paramString.match(doublePattern);
  let res = paramString;
  singlematcherArr &&
    singlematcherArr.map((item) => {
      let key = item.substring(1, item.length - 1);
      if (isParamsString(key)) {
        const currentParams = getParamsItemByString(paramsStore, key);
        let repalceKey = typeof currentParams?.value == "string";
        res = res.replace(
          repalceKey ? key : item,
          repalceKey
            ? currentParams?.value
            : JSON.stringify(currentParams?.value)
        );
      }
    });
  doublematcherArr &&
    doublematcherArr.map((item) => {
      let key = item.substring(1, item.length - 1);
      if (isParamsString(key)) {
        const currentParams = getParamsItemByString(paramsStore, key);
        let repalceKey = typeof currentParams?.value == "string";
        res = res.replace(
          repalceKey ? key : item,
          repalceKey
            ? currentParams?.value
            : JSON.stringify(currentParams?.value)
        );
      }
    });
  return res;
}

export function getAllParams(paramString) {
  let arr = [];
  let paramsType = getParamsTypeByString(paramString);
  if (paramsType === SelectParamsType.PARAMS) {
    arr.push(paramString);
  } else if (paramsType === SelectParamsType.MULTI) {
    arr = JSON.parse(paramString);
  } else if (
    paramsType === SelectParamsType.TEXT ||
    paramsType === SelectParamsType.NORMAL
  ) {
    arr.push(paramString);
  } else if (paramsType === SelectParamsType.JSCODE) {
    let singlePattern = /'#{.*?\}'/g;
    let doublePattern = /"#{.*?\}"/g;
    let singlematcherArr = paramString.match(singlePattern);
    let doublematcherArr = paramString.match(doublePattern);
    singlematcherArr &&
      singlematcherArr.map((item) => {
        let key = item.substring(1, item.length - 1);
        if (isParamsString(key)) {
          arr.push(key);
        }
      });
    doublematcherArr &&
      doublematcherArr.map((item) => {
        let key = item.substring(1, item.length - 1);
        if (isParamsString(key)) {
          arr.push(key);
        }
      });
  }
  return arr;
}

//根据保存的参数值获取当前参数
export function getParamsItemByString(
  paramsStore: any,
  paramString: string
): IGlobalParam | IEventsEleParams {
  const paramsItem = getParamsValueByKey(paramString);
  const id = paramsItem.id; //参数id
  const type = paramsItem.type;
  if (type == "event") {
    const pid = paramsItem.pid;
    const params = paramsStore.getEvetParam(pid);
    const param = params.find((item) => item.key == id);
    return param ?? { value: "" };
  } else if (paramsStore.currentEleParams[id]) {
    // 组件参数
    const paramsObj = paramsStore.currentEleParams[id];
    const paramsKey = getParamsValueByKey(paramString).key; //参数key
    for (let key in paramsObj) {
      if (paramsObj[key].key == paramsKey) {
        return paramsObj[key];
      }
    }
  } else if (type == "constant") {
    paramsItem.value = eval(paramsItem.value);
    return paramsItem;
  } else {
    // 全局变量
    let currentGlobalParam = paramsStore.globalParams.find(
      (item) => item.id == id
    );
    !currentGlobalParam &&
      (currentGlobalParam = paramsStore.cusGlobalParams.find(
        (item) => item.id == id
      ));
    return getGlobalParamsValueByType(currentGlobalParam);
  }
}

type IParamsKey = "id" | "type" | "value" | "key" | "pid"; //参数解析后的key

//根据参数储存值解析出相对应的id，key，type
export function getParamsValueByKey(
  paramString: string
): Record<IParamsKey, string> {
  let result = {} as Record<IParamsKey, string>;
  let str = paramString.slice(2, paramString.length - 1);
  const strArr = str.split(",");
  strArr.forEach((item) => {
    const itemArr = item.split("=");
    result[itemArr[0]] = itemArr[1];
  });
  return result;
}

//获取参数选择器类型
export function getParamsTypeByString(paramString: any): any {
  let result = "";
  if (isString(paramString)) {
    if (isJsonArrayString(paramString)) {
      result = SelectParamsType.MULTI;
    } else if (isParamsString(paramString)) {
      result = SelectParamsType.PARAMS;
    } else if (isCustomJsString(paramString)) {
      result = SelectParamsType.JSCODE;
    } else {
      result = SelectParamsType.TEXT;
    }
  } else if (
    typeof paramString === "object" &&
    paramString !== null &&
    ["array", "object"].includes(paramString.type)
  ) {
    result = SelectParamsType.JSONTREE;
  } else {
    result = SelectParamsType.NORMAL;
  }
  return result;
}

//不同类型的全局变量获取方法
export function getGlobalParamsValueByType(globalParam: IGlobalParam): any {
  if (globalParam) {
    if (["WT-TOKEN", "WT-OPENID"].includes(globalParam.id)) {
      let val = getCookie(globalParam.id) ?? globalParam.value;
      globalParam.value = val;
    } else if (globalParam.type == "URL") {
      let result = getUrlParam(globalParam.keyName ?? globalParam.id);
      if (result) {
        globalParam.value = result;
      }
    } else if (globalParam.type == "SESSION" || globalParam.type == "builtIn") {
      let val = getSessionItem(globalParam.id);
      val = val ? val : globalParam.value;
      let numberVal = Number(val);
      if (!isNaN(numberVal) && val != '') {
        globalParam.value = numberVal;
      } else {
        try {
          // 判断是否为json对象
          let jsonObj = JSON.parse(val);
          typeof jsonObj == "object"
            ? (globalParam.value = jsonObj)
            : (globalParam.value = val);
        } catch (error) {
          globalParam.value = val;
        }
      }
    } else if (globalParam.type == "SCRIPT") {
      globalParam.value = runCustomJs(
        "",
        globalParam.func,
        "",
        globalParam.dependencies
      );
    }
  }
  return globalParam;
}

//将json编辑器处理的树形数据处理成json
function treeToJson(paramsStore: any, tree) {
  function traverse(node) {
    const isObject = node.type === "object";
    const isArray = node.type === "array";
    let json;
    if (isObject) {
      json = {};
      node.children.forEach((item) => {
        if (item.key !== "") {
          json[item.key] = traverse(item);
        }
      });
    } else if (isArray) {
      json = [];
      node.children.forEach((item) => {
        json.push(traverse(item));
      });
    } else {
      json = getParamsValue(paramsStore, node.value);
    }
    return json;
  }

  return traverse(tree);
}
