import { IPage, Page } from "./page";

export interface IProject {
  id: number;

  /** 名称 */
  name: string;

  /** 描述 */
  description: string;

  /** 固定页面 + 普通页面 */
  pages: IPage[];

  /** 固定页面 */
  fixedPages: IPage[];

  /** 普通页面 */
  editPages: IPage[];
}

export interface ShotKeyItem {
  name: string;
  code: string;
  ctrlKey: boolean;
  handler: () => void;
}

// 工程列表中的工程数据
export interface IProjectData {
  appId: number, // 应用id
  createId: number,
  createName: string, // 创建人名称
  createTime: string, // 创建时间
  defaultFlag: number, // 是否是默认工程 0 不是，1 是
  id: number, // 工程id
  industryId?: number,
  locked: number, // 0 未锁定，1 已锁定
  name: string, // 工程名称
  source: number,
  state: number,
  type: number,
  updateId: number,
  updateName: string, // 更新人名称
  updateTime: string, // 更新时间
  selected?: boolean, // 是否是选中状态
  synopsis: string // 工程简介
}

export class Project implements IProject {
  public static create(type?: string, p?: IProject) {
    const project = new Project();
    if (type === "editor") {
      if (p) {
        project.id = p.id || project.id;
        project.name = p.name || project.name;
        project.description = p.description || project.description;
        project._pages = p.pages.map((page) => Page.create(page));

        // 初始化的时候数据需要同步保存在editPages
        project._editPages = p.pages.map((page) => Page.create(page));
      }
    } else {
      project.addPage(Page.create());
    }
    return project;
  }

  public id: number;

  public name = "New Project";

  public description = "New Project Description";

  private _pages: Page[] = [];

  // 被固定的页签
  private _fixedPages: Page[] = [];

  // 普通页签
  private _editPages: Page[] = [];

  public get pages() {
    return this._pages.map((p) => p.getRecord());
  }

  public get fixedPages() {
    return this._fixedPages.map((p) => p.getRecord());
  }

  public get editPages() {
    return this._editPages.map((p) => p.getRecord());
  }

  private updatePages() {
    this._pages = [...this._fixedPages, ...this._editPages];
  }

  public addPage(page?: IPage) {
    this._editPages.push(Page.create(page));
    this.updatePages();
  }

  public setProjectId(id: number) {
    this.id = id;
  }

  /**
   * 通过id查找页面
   * @author zhangsy 2023年11月02日 15:59:38
   * @param {id} 页面id
   * @return param
   * */
  public getPageById(id: string) {
    return this._pages.find(item => item.id == id)
  }

  public removePage(pageId: string) {
    const index = this._editPages.map((item) => item.id).indexOf(pageId);
    if (index >= 0) {
      this._editPages.splice(index, 1);
    }
    this.updatePages();
  }

  public removeOtherPages(pageId: string) {
    this._editPages = this._editPages.filter(item => item.id === pageId);
    this.updatePages();
  }

  public slicePages(startIndex, endIndex) {
    this._editPages = this._editPages.slice(startIndex, endIndex);
    this.updatePages();
  }

  /**
   * 固定页签
   * @author zhangsy 2023年11月17日 10:46:03
   * @param param
   * @return param
   * */
  public fixPage(pageId: string) {
    const pageIndex = this._editPages.findIndex(
      (item) => item.id === pageId
    );

    const page = this._editPages[pageIndex];
    this._editPages.splice(pageIndex, 1);
    this._fixedPages.push(Page.create(page));
    this.updatePages();
  }

  /**
   * 取消固定页签
   * @author zhangsy 2023年11月17日 10:46:03
   * @param param
   * @return param
   * */
  public cancelFixPage(pageId: string) {
    const pageIndex = this._fixedPages.findIndex(
      (item) => item.id === pageId
    );
    const page = this._fixedPages[pageIndex];
    this._fixedPages.splice(pageIndex, 1);
    this._editPages.unshift(Page.create(page));
    this.updatePages();
  }

  public clearPages() {
    this._fixedPages = [];
    this._editPages = [];
    this.updatePages();
  }

  public insertPage(index: number, page: Page) {
    this._editPages.splice(index, 0, page);
  }

  public getRecord(): IProject {
    return {
      id: this.id,
      name: this.name,
      description: this.description,
      pages: this.pages,
      fixedPages: this.fixedPages,
      editPages: this.editPages,
    };
  }
}

// 分片上传参数
export interface IChunksUpload {
  file: any, //文件
  chunkSize: number, //分片大小
  folderId: number, //文件夹id
  chunks?: object[], //分片对象
  md5?: any, //文件md5码
  currentPieces?: number, //片数
  index?: number, //索引
  chunkPartition?: number //时间戳
}
