/*
 * @Author: zhutj
 * @Date: 2023-12-18 16:19:31
 * @LastEditors: zhutj
 * @LastEditTime: 2024-05-25 20:03:22
 * @Description:
 * @FilePath: \s_pageeditorv3\工作库\05.项目编码阶段\源代码\packages\apis\src\utils\handleResponse.ts
 */
import { AxiosResponse } from "axios";
import { ElMessage } from "element-plus";
import { getLocalStorageItem } from "fusion-core";

export default function handleResponses(response: AxiosResponse) {
  let result = response.data,
    config = response.config;
  let projectSetting = JSON.parse(getLocalStorageItem("projectSetting"));
  const resType =
    (config?.headers?.responseType as string) ?? config.responseType;
  if (resType && ["arraybuffer", "blob"].includes(resType)) {
    return result;
  } else if (result && result?.code == "0") {
    return result;
  } else if (
    config &&
    window["deployConfig"].routerUrl.includes(config?.baseURL)
  ) {
    // 直接通过路径从服务器获取数据
    return result;
  } else {
    let path = window.location.hash.split("?")[0] ?? "";

    //如果是编辑器并且打开了提示
    if (!window["deployConfig"].appId && projectSetting?.base?.isErrorTips) {
      showMessage();
    } else if (window["deployConfig"]!.isErrorTips) {
      showMessage();
    } else if (path.includes("/all")) {
      showMessage();
    }

    return Promise.reject(result);
  }
  function showMessage() {
    if (result?.code == -1) {
      if (
        result?.msg &&
        document.getElementsByClassName("el-message").length == 0
      )
        ElMessage.error(result?.msg);
    } else if (result?.code == "00011" && document.getElementsByClassName("el-message").length == 0) {
      const router = window["PAGEEDITOR"]["$router"];
      const routerList = router.options.routes;
      const loginPage = routerList.find((item) => item.path == "/login");
      ElMessage({
        message: `${result?.msg}，页面跳转中...`,
        type: "warning",
        onClose: () => {
          if (loginPage) {
            router.push({
              path: "/login",
              query: {
                redirect_url: window.location.href,
              },
            });
          } else {
            router.push({
              path: "/",
              query: {
                redirect_url: window.location.href,
              },
            });
          }
        },
      });
    } else {
      if (
        result?.msg &&
        document.getElementsByClassName("el-message").length == 0
      )
        ElMessage.warning(result?.msg);
    }
  }
}
