/*
 * @Descripttion:
 * @Author: zhutj
 * @Date: 2023-11-28 11:21:28
 * @LastEditors: zhutj
 * @LastEditTime: 2023-11-28 15:09:52
 */
import type { Router} from 'vue-router';
import { createNoLoginGuard } from './noLoginGuard';
import { createPrmssnGuard } from './prmssnGuard';

export function setupRouterGuard(router: Router) {
  createNoLoginGuard(router);
  createPrmssnGuard(router);
}
