import { useEleDirective, setLoginInfo, getUrlParam } from "fusion-core";

//设置工程信息
export function setAppInfo() {
  const requestHeader = window["BaseConfig"].requestHeader;
  setLoginInfo({
    [requestHeader.APPID]: getUrlParam(requestHeader.APPID),
    [requestHeader.APPDOMAIN]: getUrlParam(requestHeader.APPDOMAIN),
    [requestHeader.APPNAME]: getUrlParam(requestHeader.APPNAME),
    [requestHeader.OPENID]: getUrlParam(requestHeader.OPENID),
    [requestHeader.TOKEN]: getUrlParam(requestHeader.TOKEN),
  });
}

//初始化工程
export function initProject(app: any) {
  setAppInfo();
  useEleDirective(app);
}
