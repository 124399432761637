import axios, {
  AxiosResponse,
  AxiosRequestConfig,
  AxiosInstance,
  CancelTokenStatic,
} from "axios";
import paramSerialize from "../utils/serialize";
import { IAxiosHead, IAxiosOption } from "../interface/header";
import getHeader from "../utils/getHeader";
import handleResponse from "../utils/handleResponse";
import { getHeadItemByKey, getSessionItem } from "fusion-core";

export class CurrentRequest {
  // `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL。
  public service: AxiosInstance = axios;
  protected axiosRequestConfig: AxiosRequestConfig = {};
  private static _instance: CurrentRequest;

  // 取消重复请求相关变量
  protected CancelToken: CancelTokenStatic = axios.CancelToken;
  protected pending: Array<{ url: string; cancel: Function }> = [];
  constructor(baseurl: string, head: IAxiosHead) {
    // 初始化配置
    this.requestConfig(baseurl, head);

    // 创建实例
    this.service = axios.create(this.axiosRequestConfig);

    // 请求拦截器
    this.interceptorsRequest();

    // 返回拦截器
    this.interceptorsResponse();
  }

  /**
   * 初始化配置
   * @protected
   */
  protected requestConfig(url, head): void {
    const deployConfig = (window as any).deployConfig;
    const requestHeader = (window as any).BaseConfig.requestHeader;
    const _url = !url
      ? url
      : import.meta.env.VITE_ENV === "development"
      ? url.replace(deployConfig!.baseAPIUrl, "")
      : url;
    let timeOut = deployConfig?.requestTimeout
      ? deployConfig.requestTimeout * 1000
      : 10 * 1000;

    this.axiosRequestConfig = {
      baseURL: _url,
      timeout: timeOut,
      headers: {},
    };

    //当是行业平台的时候增加请求头
    if (
      deployConfig?.fromApp ||
      (getSessionItem("WT-ISAGP") == "AGP" && getSessionItem("WT-APPTYPE") == "NORMAL")
    ) {
      this.axiosRequestConfig.headers["WT-FROMAPP"] =
      deployConfig?.fromApp ?? getHeadItemByKey(requestHeader.APPID);
    }
    if (head[requestHeader.PROJECTID]) {
      this.axiosRequestConfig.headers[requestHeader.PROJECTID] = head[requestHeader.PROJECTID];
    }
  }

  /**
   * 请求拦截
   * @protected
   */
  protected interceptorsRequest(): void {
    const deployConfig = (window as any).deployConfig;
    this.service.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        //超时白名单匹配
        if (deployConfig.requestTimeoutWhiteList) {
          const matchedApi = deployConfig.requestTimeoutWhiteList.find((obj) => obj.url.includes(config.url));
          config.timeout = matchedApi ? matchedApi.timeout : config.timeout;
        }

        //记录请求key
        // const keyOfRequest = this.getKeyOfRequest(config);

        //取消重复请求
        // this.removePending(keyOfRequest, true);
        // config.cancelToken = new this.CancelToken((c: any) => {
        //   this.pending.push({
        //     url: keyOfRequest,
        //     cancel: c,
        //   });
        // });

        // 处理token信息等
        config.headers = getHeader(config.headers);

        // 路由重定向控制
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  /**
   * 响应拦截
   * @protected
   */
  protected interceptorsResponse(): void {
    this.service.interceptors.response.use(
      (response: AxiosResponse) => {
        // 处理返回状态
        return handleResponse(response);
      },
      (error) => {
        const { response } = error;
        if (response === undefined) {
          return Promise.reject(new Error(error));
        } else {
          return handleResponse(response);
        }
      }
    );
  }

  /**
   * post方法
   * @author zhutj 2023年10月14日 17:31:56
   * @param url请求相对地址
   * @param data请求入参
   * @param headerConfig请求头自定义配置
   * @param config请求配置
   * @return param
   * */
  public post(
    url: string,
    data: any = {},
    headerConfig: IAxiosOption,
    config
  ): Promise<any> {
    // 处理请求头信息
    this.axiosRequestConfig.headers = {
      ...this.axiosRequestConfig.headers,
      ...headerConfig,
    };

    // 单独处理查询方案接口，由于查询方案参数不固定，需将平铺的数据源参数结构做二次处理，此接口一定为post,处理的结构已与后端达成约定，请勿动
    let params = null;
    if (url == "/meta/doWTScheme") {
      let _parameters = {};
      Object.keys(data).forEach((v) => {
        if (v != "schemeId") {
          _parameters[v] = data[v];
        }
      });
      params = {
        schemeId: data.schemeId, //方案id
        parameters: _parameters,
      };
    } else {
      params = data;
    }
    return new Promise((resolve, reject) => {
      this.service
        .post(
          url,
          headerConfig["Content-Type"] ==
            "application/x-www-form-urlencoded;charset=UTF-8"
            ? paramSerialize(params)
            : params,
          {
            ...this.axiosRequestConfig,
            ...config,
          }
        )
        .then((result) => {
          resolve(result);
        }, reject);
    });
  }

  /**
   * get方法
   * @author zhutj 2023年10月14日 17:31:56
   * @param url请求相对地址
   * @param data请求入参
   * @param config请求自定义配置
   * @return param
   * */
  public get(
    url: string,
    params: any = {},
    headerConfig: IAxiosOption,
    config
  ): Promise<any> {
    // 处理请求头信息
    this.axiosRequestConfig.headers = {
      ...this.axiosRequestConfig.headers,
      ...headerConfig,
    };
    return new Promise((resolve, reject) => {
      this.service
        .get(`${url}${params ? "?" + paramSerialize(params) : ""}`, {
          ...this.axiosRequestConfig,
          ...config,
        })
        .then((result) => {
          resolve(result);
        }, reject);
    });
  }

  /**
   * delete方法
   * @author zhutj 2023年10月14日 17:31:56
   * @param url请求相对地址
   * @param data请求入参
   * @param config请求自定义配置
   * @return param
   * */
  public delete(
    url: string,
    params: any = {},
    config: IAxiosOption
  ): Promise<any> {
    // 处理请求头信息
    this.axiosRequestConfig.headers = {
      ...this.axiosRequestConfig.headers,
      ...config,
    };
    return new Promise((resolve, reject) => {
      this.service
        .delete(
          `${url}${params ? "?" + paramSerialize(params) : ""}`,
          this.axiosRequestConfig
        )
        .then((result) => {
          resolve(result);
        }, reject);
    });
  }

  /**
   * put方法
   * @author zhutj 2023年10月14日 17:31:56
   * @param url请求相对地址
   * @param data请求入参
   * @param config请求自定义配置
   * @return param
   * */
  public put(
    url: string,
    params: any = {},
    config: IAxiosOption
  ): Promise<any> {
    // 处理请求头信息
    this.axiosRequestConfig.headers = {
      ...this.axiosRequestConfig.headers,
      ...config,
    };
    return new Promise((resolve, reject) => {
      this.service.put(url, params, this.axiosRequestConfig).then((result) => {
        resolve(result);
      }, reject);
    });
  }

  /**
   * 获取请求配置拼装的key
   * @param config
   * @protected
   */
  protected getKeyOfRequest(config: AxiosRequestConfig): string {
    let key = config.url;
    if (config.params) key += JSON.stringify(config.params);
    if (config.data) key += JSON.stringify(config.data);
    key += `&request_type=${config.method}`;
    return key as string;
  }

  protected removePending(key: string, request: boolean = false): void {
    this.pending.some((item, index) => {
      if (item.url === key) {
        if (request) {
          item.cancel();
          this.pending.splice(index, 1);
          return true;
        }
      }
      return false;
    });
  }

  /**
   * 创建唯一实例（单例模式）
   */
  public static getInstance(baseUrl: string, head: IAxiosHead): CurrentRequest {
    // 如果 instance 是一个实例 直接返回，  如果不是 实例化后返回
    const requestHeader = (window as any).BaseConfig.requestHeader;
    if (
      this._instance &&
      baseUrl == this._instance.axiosRequestConfig.baseURL &&
      head[requestHeader.PROJECTID] ==
        this._instance.axiosRequestConfig.headers.projectId
    ) {
      return this._instance;
    } else {
      this._instance = new CurrentRequest(baseUrl, head);
      return this._instance;
    }
  }
}
