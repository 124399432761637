/*
 * @Descripttion: 生成随机id
 * @Author: @zhutj
 * @ Modified by: zhangsy
 * @ Modified time: 2024-06-04 16:23:57
 * @LastEditTime: 2023-12-14 15:49:32
 */
import {
  BuildEnv,
  Environment,
  RunEnv,
  IconTypeEnum,
  FileSuffixEnum,
  IconRequestTypeEnum,
} from "../enums/index";
import type { TreeNode, IConditionType } from "../interface/helper";
import { getIconfont } from "../service";
import { getParamsValue, isObject } from "./index";
import { PageElement, IElement, IBackground } from "../interface/project";
import { getHeadItemByKey } from "./handleATUtils";

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return "E" + s4() + s4();
}

export function nanoid() {
  let preAlphabet = "abcdefghijklmnopqrstuvwsyz";
  let urlAlphabet =
    "useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict";
  let preid = (size = 4) => {
    let id = "";
    let i = size;
    while (i--) {
      id += preAlphabet[(Math.random() * 26) | 0];
    }
    return id;
  };
  let nanoid = (size = 21) => {
    let id = "";
    let i = size;
    while (i--) {
      id += urlAlphabet[(Math.random() * 64) | 0];
    }
    return id;
  };
  return preid() + nanoid();
}

export function randomPageId() {
  var rnd = "";
  for (var i = 0; i < 12; i++) {
    rnd += Math.floor(Math.random() * 10);
  }
  return Number(rnd);
}

// 删除组件
export function removeElement(elements, id) {
  let index = elements.findIndex((item) => item.id == id);
  if (index > -1) {
    elements.splice(index, 1);
  } else {
    elements.map((item) => {
      if (item.children && item.children.length) {
        removeElement(item.children, id);
      }
    });
  }
  return elements;

  // 下面的写法会导致组件数据失去响应式
  // return elements
  //   .filter((item) => {
  //     if (item.id != id) {
  //       return true;
  //     }
  //   })
  //   .map((item) => {
  //     if (item.children && item.children.length) {
  //       item.children = removeElement(item.children, id);
  //     }
  //     return item;
  //   });
}

/**
 * 添加组件
 * @param elements 组件库
 * @param element 需要添加的组件
 * @param parentId 组件父id
 * @return 新的组件库
 * */
export function addElement(
  elements: IElement[],
  element: IElement,
  currentId?: string,
  parentId?: string
): IElement[] {
  if (!parentId) {
    elements.push(PageElement.create(element));
    return elements;
  } else {
    for (let i = 0; i < elements.length; i++) {
      const item = elements[i];
      if (item.id === parentId) {
        element.pid = parentId;
        if (currentId) {
          const currentIndex = item.children.findIndex(
            (child) => child.id == currentId
          );
          if (currentIndex > -1) {
            item.children.splice(
              currentIndex + 1,
              0,
              PageElement.create(element)
            );
          } else {
            item.children.push(PageElement.create(element));
          }
        } else {
          (item.children || (item.children = [])).push(
            PageElement.create(element)
          );
        }
      } else if (item.children && item.children.length) {
        addElement(item.children, element, currentId, parentId);
      }
    }
    return elements;
  }
}

export function getEnv(data: Environment): BuildEnv | RunEnv {
  if (
    data === Environment.BUILD_ENV &&
    process.env.NODE_ENV === BuildEnv.EDITOR
  ) {
    // 本地开发
    if (window.location.href.includes("preview")) {
      return BuildEnv.PREVIEW;
    } else {
      return BuildEnv.EDITOR;
    }
  } else {
    // 线上
    let value: any;
    if (data === Environment.BUILD_ENV) {
      if (window.location.href.includes("preview")) {
        return BuildEnv.PREVIEW;
      }
      value = BuildEnv;
    } else if (data === Environment.RUN_ENV) {
      value = RunEnv;
    }
    for (let key in value) {
      if (process.env.NODE_ENV === value[key]) {
        return value[key];
      }
    }
  }
}

export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key])
          Object.assign(target, {
            [key]: {},
          });
        if (JSON.stringify(source[key]) != "{}") {
          target[key] = mergeDeep(target[key], source[key]);
        } else {
          target[key] = source[key];
        }
      } else {
        Object.assign(target, {
          [key]: source[key],
        });
      }
    }
  } else {
    if (source != undefined) {
      target = source;
    }
  }
  return mergeDeep(target, ...sources);
}

export function deepClone(data, exceptKeyArray?) {
  const type = judgeType(data);
  let obj;
  if (type === "array") {
    obj = [];
  } else if (type === "object") {
    obj = {};
  } else {
    // 不再具有下一层次
    return data;
  }
  if (type === "array") {
    // eslint-disable-next-line
    for (let i = 0, len = data.length; i < len; i++) {
      obj.push(deepClone(data[i], ""));
    }
  } else if (type === "object") {
    // 对原型上的方法也拷贝了....
    // eslint-disable-next-line
    for (const key in data) {
      if (exceptKeyArray && exceptKeyArray.includes(key)) {
        obj[key] = data[key];
      } else {
        obj[key] = deepClone(data[key], "");
      }
    }
  }
  return obj;
}

function judgeType(obj) {
  // tostring会返回对应不同的标签的构造函数
  const toString = Object.prototype.toString;
  const map = {
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object String]": "string",
    "[object Function]": "function",
    "[object Array]": "array",
    "[object Date]": "date",
    "[object RegExp]": "regExp",
    "[object Undefined]": "undefined",
    "[object Null]": "null",
    "[object Object]": "object",
  };
  if (obj instanceof Element) {
    return "element";
  }
  return map[toString.call(obj)];
}

/**
 * 批量加载资源
 * @param { Array }  组件依赖地址
 */
export function loadScriptAll(src: Array<string>) {
  let promsieList = [];
  src.forEach((item) => {
    let compInstance = window["PAGEEDITOR"]["compInstance"];
    const compName = item.split(".")[0].replace("comp/js/", "");
    let p = undefined;
    if (compInstance && compInstance[compName]) {
      p = new Promise((resolve) => {
        resolve(item);
      });
    } else {
      p = new Promise((resolve, reject) => {
        const sc = document.createElement("script");
        function onLoad() {
          resolve(item);
          sc.onload = sc.onerror = null;
        }
        sc.onload = onLoad;
        sc.onerror = reject;
        sc.src = item;
        sc.crossOrigin = "anonymous";
        document.head.append(sc);
      });
    }
    promsieList.push(p);
  });
  return promsieList;
}

/**
 * 批量加载css
 * @param { Array }  组件依赖地址
 */
export function loadCssAll(src: Array<string>) {
  let promsieList = [];
  src.forEach((item) => {
    let p = new Promise((resolve, reject) => {
      const sc = document.createElement("link");
      function onLoad() {
        resolve(item);
        sc.onload = sc.onerror = null;
      }
      sc.onload = onLoad;
      sc.onerror = reject;
      sc.href = item;
      sc.rel = "stylesheet";
      sc.type = "text/css";

      // sc.crossOrigin = 'anonymous';
      document.head.append(sc);
    });
    promsieList.push(p);
  });
  return promsieList;
}

/**
 * description 同步加载依赖
 * @author zhutj 2023年11月21日 18:25:10
 * @param str 资源地址
 * @param callback 回调方法
 * @return param
 * */
export function loadScriptSync(src: string, callback?: Function): void {
  const sc = document.createElement("script");
  sc.async = false;
  sc.src = src;
  document.head.append(sc);
  sc.onload = function () {
    if (callback) {
      callback();
    }
  };
}

//树形转化器，列表转化为树结构，要求子集必须包含id与pid来进行结构处理，pid为“-1”时，代表顶级结构，子集children
export function convertListToTree(arr: []): TreeNode[] {
  let list = deepClone(arr); //克隆数组，防止值污染
  let tree = []; //最后返回的树结构数据
  //优先寻找顶级结构
  for (let i = 0; i < list.length; i++) {
    const ELE = list[i];
    if (ELE.pid == "-1") {
      ELE.children = [];
      tree.push(ELE);
      list.splice(i, 1);
      i--;
    }
  }

  //子节点获取方法
  let setChildren = (childTree: TreeNode[]) => {
    if (list.length > 0) {
      for (let i = 0; i < childTree.length; i++) {
        const ELE = childTree[i];
        for (let j = 0; j < list.length; j++) {
          const CHILD = list[j];
          if (CHILD.pid == ELE.id) {
            (ELE.children || (ELE.children = [])).push(CHILD);
            list.splice(j, 1);
            j--;
          }
        }
        if (ELE.children && ELE.children.length > 0) {
          setChildren(ELE.children); //继续寻找子集
        }
      }
    }
  };
  setChildren(tree);
  setChildren = null;
  return tree;
}

export function arrayToTree(array: any, parentName = "parentId") {
  const tree = [];
  const nodeMap = {} as any;
  for (const item of array) {
    const { id } = item;
    nodeMap[id] = {
      ...item,
      children: [],
    };
  }

  // 构建树形结构
  for (const item of array) {
    const { id } = item;
    const parentId = item[parentName];
    const node = nodeMap[id];
    if (!nodeMap[parentId]) {
      tree.push(node);
    } else {
      const parentNode = nodeMap[parentId];
      parentNode.children.push(node);
    }
  }
  return tree;
}

//对象建模相关可使用 列表转树形
export function listConvertToTree(
  array: Array<any>,
  parentId = null,
  parentName = "parent_id"
): any {
  const tree = [];
  for (let i = 0; i < array.length; i++) {
    if (array[i][parentName] === parentId) {
      const children = listConvertToTree(array, array[i].id, parentName);
      const node = {
        ...array[i],
        name: array[i].node_name,
        hasChildren: children.length > 0,
        children: children,
      };
      tree.push(node);
    }
  }
  return tree;
}

/**
 * 将组件参数转成树形结构
 * @param projectStore store数据
 * @param obj 组件参数
 * @return param
 * */
export function convertEleParamsToTree(
  projectStore: any,
  elementsParams: any
): TreeNode[] {
  let result = [];
  for (let key in elementsParams) {
    let itemParams = elementsParams[key];
    let itemList = [];
    for (let paramKey in itemParams) {
      let itemParamsValue = { ...itemParams[paramKey] };
      itemParamsValue.id = key + itemParamsValue.key;
      itemParamsValue.pid = key;
      itemList.push(itemParamsValue);
    }
    let itemObj = {
      id: key,
      label: projectStore.getElementById(key)?.title,
      children: itemList,
    };
    result.push(itemObj);
  }
  return result;
}

interface MultliParams {
  globalParamsToTree: TreeNode;
  currentEleParamsToTree: any;
  eventParams: any;
}

/**
 * 获取组件参数列表和全局变量列表
 * @param paramsStore store数据
 * @return 返回的参数
 * */
export function getGlobalParams(
  paramsStore: any,
  eventId?: string
): MultliParams {
  const globalParamsToTree = paramsStore.globalParamsToTree;
  const currentEleParamsToTree = paramsStore.currentEleParamsToTree;
  const eventParams = paramsStore.getEvetParam(eventId);
  return {
    globalParamsToTree,
    currentEleParamsToTree,
    eventParams,
  };
}

/**
 * 加载字体
 * @param fontName 文字classname
 * @param fontUrl 文字地址
 * @return param
 * */
export async function loadFont(fontName: string, fontUrl: string) {
  const font = new FontFace(fontName, `url(${fontUrl})`);
  await font.load();
  document.fonts.add(font);
}

/**
 * 加载字体
 * @param fontName 文字classname
 * @param fontUrl 文字地址
 * @return param
 * */
export async function loadFontFailmy(fontName: string, fontUrl: string) {
  // const font = new FontFace(fontName, `url(${fontUrl})`);
  // await font.load();
  //  document.fonts.add(font);

  const fontname = fontName;
  const style = document.createElement("style");
  style.innerHTML = `
   @font-face {
     font-family: ${fontname};
     src: url("${fontUrl}");
   }
   html {
     font-family: ${fontname};
   }
   `;
  document.head.appendChild(style);
}

//加载图标
export function loadIconfont() {
  getIconfont({ type: IconRequestTypeEnum.PROJECT }).then((res) => {
    if (res.code == "0") {
      let jsArr: string[] = [];
      let cssArr: string[] = [];
      let iconfontJson = [];
      res.data.forEach((item) => {
        let fullPath = window["deployConfig"].dfsUrlSuffix + item.path;
        let className =
          item.type == 1 ? IconTypeEnum.BASE : IconTypeEnum.PROJECT;
        let suffix = item.path.split(".")[1];
        if (
          [
            FileSuffixEnum.TTF,
            FileSuffixEnum.WOFF,
            FileSuffixEnum.WOFF2,
          ].includes(suffix)
        ) {
          if (item.fileType && item.fileType == "1") {
            loadFontFailmy(item.name, fullPath);
          } else {
            loadFont(className, fullPath);
          }
        }
        if (suffix === FileSuffixEnum.JS) {
          jsArr.push(fullPath);
        }
        if (suffix === FileSuffixEnum.CSS) {
          cssArr.push(fullPath);
        }

        if (suffix === FileSuffixEnum.JSON) {
          item.fullPath = fullPath;
          iconfontJson.push(item);
        }
      });
      let GlobalInstance = (window as any)["PAGEEDITOR"].GlobalInstance;
      GlobalInstance.setGlobalParams("iconfontJson", iconfontJson);
      loadScriptAll(jsArr);
      loadCssAll(cssArr);
    }
  });
}

//获取条件判断结果
export function getConditionResult(
  conditionsArr: IConditionType[],
  paramStore: any
): boolean {
  let rst = true;
  let isTure = true;
  conditionsArr.forEach((item) => {
    let prevTarget = getParamsValue(paramStore, item.prevTarget);
    let nextTarget = getParamsValue(paramStore, item.nextTarget);
    let val1 = Number(prevTarget),
      val2 = Number(nextTarget); //数字转换
    let isNotNum = isNaN(val1) || isNaN(val2),
      checkVal = "",
      isSpecialEstimate = ["in", "notIn"].includes(item.estimate);
    if (isSpecialEstimate) {
      item.estimate == "notIn" ? checkVal = `!'${prevTarget}'.includes('${nextTarget}')` : checkVal = `'${prevTarget}'.includes('${nextTarget}')`;
    } else if (isNotNum) {
      checkVal = `'${prevTarget}'${item.estimate}'${nextTarget}'`;
    } else {
      checkVal = val1 + item.estimate + val2;
    }
    isTure = eval(checkVal);
    if (item.connection == "||") {
      rst = isTure || rst;
    } else {
      rst = isTure && rst;
    }
  });
  return rst;
}

export function getUrlParam(name) {
  const pattern = new RegExp(`[?&]${name}=([^&]+)(&|$)`, "i");
  const match = pattern.exec(location.href);
  return match ? decodeURIComponent(match[1]) : null;

  // return (
  //   decodeURIComponent(
  //     (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
  //       location.href
  //     ) || [, ""])[1].replace(/\+/g, "%20")
  //   ) || null
  // );
}

/**
 * 将字符串转化为number
 * @param data 字符串
 * @return 数字
 * */
export function getNumberByString(data: string): number {
  return Number(data.replace(/[^\d.-]/g, ""));
}

/**
 * 获取背景
 * @param bg 背景参数。需包含：backgroundType-颜色类型；backgroundValue-颜色值（纯色伙食背景链接）。
 * @return param
 * */
export function getBgStyle(bg: IBackground): string {
  if (!bg || !bg.value) return "";
  if (bg.type === "color") {
    return bg.value;
  } else if (bg.type === "img") {
    return `url(${
      window["deployConfig"].dfsUrlSuffix + bg.value.path
    }) no-repeat center center /100% 100%`;
  } else {
    return "rgba(0,0,0,0)";
  }
}

//为地址栏增加参数
export function addUrlParams(url: string, params: object) {
  if (Object.keys(params).length == 0) {
    return url;
  } else {
    if (url.indexOf("?") == -1) {
      url += "?";
    } else {
      url += "&";
    }
    let paramsArr = Object.keys(params);
    for (let i = 0, len = paramsArr.length; i < len; i++) {
      const ele = paramsArr[i];
      url += `${ele}=${params[ele]}`;
      i < len - 1 ? (url += "&") : "";
    }
    return url;
  }
}

/**
 * 跳转发布页面
 * @param pageId 页面id 不传则跳往首页
 * @return param
 * */
export function jumpReleasePage(projectStore: any, pageId?: string): void {
  const routerPath: IRouterPath[] = projectStore.routerPath;
  const projectId = projectStore.projectId;
  let path = "/";
  if (pageId) {
    path = routerPath.filter((item) => item.pageId == pageId)[0].path;
  }

  const requestHeader = (window as any).BaseConfig.requestHeader;
  const appId = getHeadItemByKey(requestHeader.APPID);

  // 发布后页面地址
  const releasePage = `${window["deployConfig"].routerUrl}${appId}/page/${projectId}/#${path}`;

  window.open(releasePage);
}

// 是否是绝对路径,并返回
function getFullPath(url) {
  let fullPath = "";
  if (url.indexOf("http") != -1) {
    fullPath = url;
  } else {
    try {
      // 处理json数组返回
      let path = eval(url);
      url = path[0]["url"];
    } catch (error) {
      url = url;
    }
    fullPath = window["deployConfig"].staticFileUrl + url;
  }
  return fullPath;
}

//获取图片全路径
export function getImageFullPath(imgObj:any){
  let fullPath = [];
  if (typeof imgObj == 'string') {
    try {
      // 判断是否为json对象
      let jsonStr = JSON.parse(imgObj);
      if (Object.prototype.toString.call(jsonStr) == '[object Array]'){
        jsonStr.forEach(imgObj => {
          fullPath.push(getFullPath(imgObj.url));
        });
      } else if (typeof jsonStr == 'object') {
        if (jsonStr.hasOwnProperty('absolutePath')) {
          fullPath.push(getFullPath(jsonStr.absolutePath));
        } else {
          fullPath.push(getFullPath(imgObj));
        }
      } else {
        imgObj.split(',').forEach(v=>{
          fullPath.push(getFullPath(v));
        });
      }
    } catch (err) {
      imgObj.split(',').forEach(v=>{
        fullPath.push(getFullPath(v));
      });
    }
  }
  return fullPath;
}

// 获取图片地址
export function getImgOrIconFullPath(data) {
  let urlOrNameObj = {
    imgFullPath: "",
    name: "",
  };
  if (typeof data == "string") {
    try {
      // 判断是否为json对象
      let jsonStr = JSON.parse(data);
      if (typeof jsonStr == "object" && jsonStr) {
        if (jsonStr.hasOwnProperty("url")) {
          urlOrNameObj.imgFullPath = getFullPath(jsonStr.url);
          urlOrNameObj.name = jsonStr.name;
        } else {
          urlOrNameObj.imgFullPath = getFullPath(data);
          urlOrNameObj.name = "";
        }
      } else {
        urlOrNameObj.imgFullPath = getFullPath(data);
        urlOrNameObj.name = "";
      }
    } catch (err) {
      urlOrNameObj.imgFullPath = getFullPath(data);
      urlOrNameObj.name = "";
    }
  }
  return urlOrNameObj;
}

//创建a标签下载
export const fileDownload = (function () {
  let eleLink;
  return (fileName, file) => {
    if (!eleLink) {
      eleLink = document.createElement("a");
      eleLink.style.display = "none";
      document.body.appendChild(eleLink);
    }
    eleLink.download = fileName;
    const blob = new Blob([file]);
    eleLink.href = URL.createObjectURL(blob);
    eleLink.click();
  };
})();

//自定义时间格式
export function formatDate(time, format = "yyyy-MM-dd") {
  if (!time) {
    return "";
  }

  if (typeof time === "string") {
    time = time.replace("T", " ").replace(new RegExp("-", "gm"), "/");
  }

  let t = new Date(time);

  if (t.toString() == "Invalid Date") {
    return time;
  }

  if (t.getTime() === 0) {
    t = new Date();
  }

  let tf = (i) => {
    return (i < 10 ? "0" : "") + i;
  };

  return format.replace(/yyyy|MM|dd|HH|mm|ss/g, (type) => {
    switch (type) {
      case "yyyy":
        return tf(t.getFullYear());
      case "MM":
        return tf(t.getMonth() + 1);
      case "mm":
        return tf(t.getMinutes());
      case "dd":
        return tf(t.getDate());
      case "HH":
        return tf(t.getHours());
      case "ss":
        return tf(t.getSeconds());
    }
  });
}

/*
 * 汉字占两个字符，英文字母占一个字符。
 */
export function checkCh(str: string) {
  let len = 0,
    strlen = str.trim().length;
  for (let i = 0; i < strlen; i++) {
    if (str.charCodeAt(i) > 255) {
      len += 2;
    } else {
      len++;
    }
  }
  return len;
}

export function debounce(fn:Function, delay = 500) {
  // 是闭包中的
  let timeoutId: ReturnType<typeof setTimeout> | null;

  return function(...args: any[]) {
     // 这个if 判断不做也没关系，判断了（除第一次非空的情况）也就是执行从第二次开始，在延迟时间内多次触发才会走该判断
     if (timeoutId) { clearTimeout(timeoutId) }

     // 此时的箭头函数的this 和 arguments 都是从外部函数继承而来
     // 如果用普通函数就要用词法作用域
     timeoutId = setTimeout(() =>{
        // 使得传入的回调函数的this 指向Input这个元素对象
        // arguments是该事件的详情，可以获得该函数被调用时的所有参数,是一个event 对象（所有Dom事件都会传event对象进入）
        fn.apply(this, args);
        timeoutId = null
     },delay)
 }
}
