/*
 * @Date: 2023-07-28 18:04:09
 * @LastEditors: zhutj
 * @LastEditTime: 2023-06-09 09:11:10
 */

import appInstance from "./appInstance";
import "animate.css";
import { useInitMainHook, initProject} from 'fusion-public-source'
import { createPinia } from "pinia";
import ElementPlus from "element-plus";
import 'element-plus/dist/index.css';
import "./assets/less/base.less";
import "./assets/less/theme.css";
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import vant from 'vant';

import {setupRouterGuard} from "./router/guard/routerGuard"

function initApp(router,app) {
	(window as any)['ZHIDaoUse'] = app.use;//为解决easyPlayer挂载问题
// // 根据环境加载相关配置文件
// try {
// 	let scriptStr = "";
// 	switch (import.meta.env.VITE_ENV) {
// 		case "development":
// 			scriptStr = "./config/config.dev.js";
// 			loadScriptSync('./config/config.js');
// 			break;
// 		case "simu":
// 			scriptStr = "./config/config.simu.js";
// 			break;
// 		case "test":
// 			scriptStr = "./config/config.test.js";
// 			break;
// 		case "production":
// 			scriptStr = "./config/config.prod.js";
// 			loadScriptSync('/v7i0_wG9/common/config/config.js');
// 			break;
// 		default:
// 	}
// 	loadScriptSync(scriptStr, loadApp);
// }

// catch (error) {
// 	alert("加载资源失败，请刷新重试");
// }
const timer = setTimeout(() => {

	loadApp();
    clearTimeout(timer)
},2000)
function loadApp() {
	useInitMainHook();

	// 挂载全局报错
	// setupErrorHandle(app,window.SDK);
	const pinia = createPinia();
	(app || appInstance).use(ElementPlus, {
		locale: zhCn,
	  }).use(pinia);
	  (app || appInstance).use(vant);
	setupRouterGuard(router);
	(app || appInstance).use(router);
	//   router.isReady().then(() => app.mount('#app'))
    (app || appInstance).mount("#app");
	initProject((app || appInstance));
}
}

export default initApp;