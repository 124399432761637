import { IMonitorOptions, IMonitorConfig } from '../interface';

let SDK = null // AgentSDK 实例对象

// 通过 web-vitals 页面性能指标
const reportWebVitals = (onPerfEntry) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getFID, getFCP, getLCP, getTTFB, }) => {
      getFID(onPerfEntry) // 首次输入延迟时间
      getFCP(onPerfEntry) // 首次内容渲染时间
      getLCP(onPerfEntry) // 首次最大内容渲染时间
      getTTFB(onPerfEntry) // 首个字节到达时间
    })
  }
}

export class AgentSDK {
  private baseUrl = '';
  private monitorData: IMonitorConfig = {
    pageId: '',
    error: [],
    action: [],
    performance: [],
  }

  constructor(options: IMonitorOptions) {
    if (SDK) return;
    this.baseUrl = options?.baseUrl;

    // 初始化监听页面变化
    this.listenPage();
  }

  // 设置 config
  setConfig(config){
    Object.assign(this.monitorData, config);
  }

  // 监听页面变化
  listenPage() {
    // let pageShowTime = 0;

    window.addEventListener('pageshow', () => {
      // pageShowTime = performance.now();

       // 页面性能指标上报
      reportWebVitals((data) => {
        this.performanceReport(data);
      });
    });

    // window.addEventListener('pagehide', () => {
    //   // 记录用户在页面停留时间
    //   this.timeOnPage = performance.now() - pageShowTime;

  }

  // 清空任务队列
  flushQueue() {
    this.monitorData.action = [];
    this.monitorData.error = [];
    this.monitorData.performance = [];
  }

  // 自定义上报类型
  report(config) {
    switch (config.category) {
      case 'action': {
        // 行为上报
        this.monitorData.action.push({...config,});
        break;
      }
      case 'performance': {
        // 性能上报
        this.monitorData.performance.push(config);
        break;
      }
      case 'error': {
        // 错误上报
        this.monitorData.error.push({...config,});
        break;
      }
      default: {
        break;
      }
    }
  }

  // 用户行为上报
  actionReport(config) {
    this.report({
      ...config,
      category: 'action',
    })
  }

  // 页面性能指标上报
  performanceReport(config) {
    this.report({
      ...config,
      category: 'performance',
    })
  }

  // 错误警告上报
  errorReport(config) {
    this.report({
      ...config,
      category: 'error',
    })
  }

  // 数据上报
  sendData() {
    const requestInstance = window['PAGEEDITOR'].requestList['RequestInstance'];
    const params = {
      pageId: this.monitorData.pageId,
      action: JSON.stringify(this.monitorData.action),
      error: JSON.stringify(this.monitorData.error),
      performance: JSON.stringify(this.monitorData.performance),
    }
    requestInstance.post(this.baseUrl, params, { 'Content-Type': "application/json;charset=UTF-8", }).then(res => {
      if (res.code === '0') {
        this.flushQueue();
      }
    });
  }

  getMonitorData() {
    return this.monitorData;
  }
}