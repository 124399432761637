/*
 * @Author: zhutj
 * @Date: 2023-07-05 11:11:01
 * @LastEditors: zhutj
 * @LastEditTime: 2024-04-24 14:38:33
 * @Description: 事件枚举
 * @FilePath: \s_pageeditorv3\工作库\05.项目编码阶段\源代码\packages\core\src\enums\eventEnum.ts
 */
/**
 * 事件类型
 */
export enum EventTypeEnum {
  RELATION = 1,//关联事件
  ROUTE = 2,//路由事件
  CALCULATION = 3,//计算脚本
  CUSTOM = 4,//自定义脚本
  DATASOURCE = 5,//数据源
  BUILTIN = 6,//内置方法
}

/**
 * 内置方法
 */
export enum BuiltInEnum {
  SIGNOUT = 1,//退出登录
  MODIFYGLOBALPARAM = 2,//修改全局参数
  RELOAD = 3,//刷新页面
  RETURNLAST = 4,//返回上一页
  EXE3D = 5,//3D执行方法
  CLOSEDIALOG = 6,//关闭弹框
  MESSAGETIP = 7,//信息提示
  DOWNLOAD = 8,
}

/**
* 跳转类型
*/
export enum SourceTypeEnum {
  PAGE = 1,//页面
  URL = 2,//链接
}

/**
* 跳转方式
*/
export enum JumpTypeEnum {
  REPLACE = 1,//覆盖本页面
  BLACK = 2,//打开新窗口
}

// 参数选择类型
export enum SelectParamsType {
  TEXT = 'text',
  PARAMS = 'params',
  MULTI = 'multi',
  JSCODE = "jscode",
  NORMAL = "normal",
  JSONTREE = "jsonTree",//增加json编辑器处理的数据类型
}

// 资源管理器文件类型
export enum ExplorerFileTypeEnum {
  IMAGE = 5, //图片
  VIDEO = 7, // 视频
  AUDIO = 9, // 音频
  FILE = 10, // 文件
}

// 资源管理器类型
export enum ExplorerTypeEnum {
  PAGE = 'page', //页面
  FILE = 'file', //文件
  ICON = 'icon', //图标
  GRAPH = 'graph', //绘图
  GIS = 'gis', //GIS
  GRID = 'grid', //GRID
  WORD = 'word', //报告
  FORM = 'form', //表单
  APP = 'app', //app选择
  PROCESSFORM = 'processForm',//工作流表单
}

// 资源管理器类型
export enum ExplorerPageTypeEnum {
  REALPAGE = 'realPage', //真实页面
  VIRTUALPAGE = 'virtualPage', //虚拟页面
}

// 资源管理器图标类型
export enum IconTypeEnum {
  BASE = 'iconfont', //基础图标
  PROJECT = 'projecticonfont', //项目图标
}

// 资源管理器图标接口
export enum IconRequestTypeEnum {
  BASE = 1, //基础图标
  PROJECT = 2, //项目图标
}

// 文件后缀枚举
export enum FileSuffixEnum {
  TTF = 'ttf',
  WOFF = 'woff',
  WOFF2 = 'woff2',
  JS = 'js',
  CSS = 'css',
  JSON = 'json'
}
