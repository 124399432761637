/*
 * @Descripttion:免登录前端拦截
 * @Author: zhutj
 * @Date: 2023-11-28 11:36:50
 * @LastEditors: zhutj
 * @LastEditTime: 2023-06-10 09:38:39
 */
import type { Router } from "vue-router";
import { ElMessage } from "element-plus";
import { getLocalStorageItem } from "fusion-core";

export function createPrmssnGuard(router: Router) {
  router.beforeEach((to, from, next) => {
    if (to.path && to.path !== "/login") {
      const pRoute = to.matched[0];
      if (pRoute.meta?.prmssn || to.meta?.prmssn) {
        const allPrmssn = JSON.parse(getLocalStorageItem("allPrmssn"));
        if (allPrmssn) {
          const path = to.path.replace(`/${pRoute.name}`, "");
          const prmssn = allPrmssn[path];
          if (prmssn == "visible") {
            next();
          } else if (prmssn == "tooltip") {
            ElMessage({
              message: "当前用户没有查看此项菜单的权限！",
              type: "error",
            });
            return;
          } else {
            // 根节点
            next();
          }
        } else if (to.path) {
          next();
        }
      } else if (to.path) {
        next();
      }
    } else if (to.path) {
      next();
    }
  });
}
