/*
 * @Descripttion: 参数序列化
 * @Author: zhutj
 * @Date: 2023-08-30 10:32:54
 * @LastEditTime: 2023-11-02 15:58:05
 */
export default function serialize(obj) {
	let ary = [];
	for (var p in obj)
		if (Object.prototype.hasOwnProperty.call(obj, p)) {
			ary.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
		}
	return ary.join("&");
}
