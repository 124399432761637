<template>
  <div id="app">
    <PreLoad :loading="isLoading" :page-data="pageData"></PreLoad>
    <div v-show="!isLoading" class="app-template">
      <keep-alive>
        <router-view v-if="isKeepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!isKeepAlive"></router-view>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, watch, provide } from "vue";
import { useRoute, useRouter } from "vue-router";
import { CurrentRequest } from "fusion-apis";
import PreLoad from "../components/page-preload.vue";
import { useProjectStore, useParamsStore, useGenStore } from "../stores/index";
import { TinyEmitter } from "tiny-emitter";
import {
  IPage,
  loadIconfont,
  LoadTypeEnum,
  dataSourcesHooks,
  IGlobalParam,
  ICustomJS,
  ATUtils,
  GlobalParamsInstance,
  setSessionItem,
} from "fusion-core";

interface IProjectGlobalData {
  projectGlobalParams: IGlobalParam[];
  projectCustomJS: ICustomJS[];
}

const props = defineProps<{
  projectData: IProjectGlobalData;
}>();

window["PAGEEDITOR"] = {
  compFunc: {},
  pageState: {
    isReleased: true,
  },
};

let GlobalInstance = GlobalParamsInstance.getInstance();

const win = window as any;
win.PAGEEDITOR.GlobalInstance = GlobalInstance;
const deployConfig = win.deployConfig;
const requestHeader = win.BaseConfig.requestHeader;
deployConfig?.fromApp && setSessionItem("WT-ISAGP", "AGP");

//全局挂载
const globalEmitter = new TinyEmitter();
win["PAGEEDITOR"]["globalEmitter"] = globalEmitter;
const requestInstance = CurrentRequest.getInstance(deployConfig.editUrl, {
  [requestHeader.PROJECTID]: deployConfig.projectId,
});
const releaseRequestInstance = CurrentRequest.getInstance(
  deployConfig.releasedUrl,
  {
    [requestHeader.PROJECTID]: deployConfig.projectId,
  }
);

// 根服务
const RootInstance = CurrentRequest.getInstance(deployConfig.rootUrl, {
  [requestHeader.PROJECTID]: deployConfig.projectId,
});

// 对象建模服务
const metaRequestInstance = CurrentRequest.getInstance(deployConfig.metaUrl, {
  [requestHeader.PROJECTID]: deployConfig.projectId,
});

// 挂载baseUrl的请求实例
const baseRequestInstance = CurrentRequest.getInstance(deployConfig.baseUrl, {
  [requestHeader.PROJECTID]: deployConfig.projectId,
});

// 路由表地址
const routerRequestInstance = CurrentRequest.getInstance(
  deployConfig.routerUrl,
  {
    [requestHeader.PROJECTID]: deployConfig.projectId,
  }
);

(window as any)["PAGEEDITOR"].requestList = {
  RootInstance,
  RequestInstance: requestInstance,
  BaseRequestInstance: baseRequestInstance,
  MetaRequestInstance: metaRequestInstance,
  ReleasedRequestInstance: releaseRequestInstance,
  RouterRequestInstance: routerRequestInstance,
};
loadIconfont(); //加载字体图标文件

const projectStore = useProjectStore();
const paramsStore = useParamsStore();
paramsStore.setGlobalParams(props.projectData.projectGlobalParams); //设置全局变量池
projectStore.setCustomJsArr(props.projectData.projectCustomJS); //设置全局变量池
const genStore = useGenStore();

const route = useRoute();
const isKeepAlive = ref(false); //路由保持参数
const pageData = ref({} as IPage); //组件数据
const isLoading = ref(false); //是否有加载层
let loadingTimeout = null;
const compInstance = ref({});
const apps = ref({
  projectStore,
  paramsStore,
  genStore,
  compInstance,
  from: "app-template",
});

provide("apps", apps.value);
win["PAGEEDITOR"]["ATUtils"] = new ATUtils(apps.value);
window["PAGEEDITOR"]["$router"] = useRouter();
dataSourcesHooks(apps.value); //数据源hook执行
//监听当前页面
watch(
  () => projectStore.currentPage,
  (newPage) => {
    if (newPage) {
      pageData.value = newPage;
      isLoading.value = newPage.attribute.loadType != LoadTypeEnum.NONE;
      if (pageData.value.elements.length == 0) {
        isLoading.value = true;
      }
    }
  },
  {
    deep: true,
    immediate: true,
  }
);

//监听当前路由
watch(
  () => route,
  (newVal) => {
    if (newVal?.name) {
      let pageArr = (newVal.name as string)?.split("-");
      let pageCode = pageArr.slice(-1)[0];

      // isKeepAlive.value = genStore.getRouterKeepAlive(pageCode); //获取当前页面保持状态
      projectStore.setCurrentPageId(pageCode); //设置当前页面ID
      let compInstance = {};
      pageArr &&
        pageArr.map((id) => {
          compInstance[id] = apps.value.compInstance[id];
        });
      apps.value.compInstance = compInstance; //不在当前页面的组件实例
    }
  },
  {
    deep: true,
    immediate: true,
  }
);

//loading计数器监听
watch(
  () => genStore.loadingCount,
  (newVal) => {
    if (isLoading.value) {
      isLoading.value = newVal > 0; //若组件全局加载完成，则关闭loading
      if (!loadingTimeout) {
        loadingTimeout = setTimeout(() => {
          isLoading.value = false;
        }, 5000);
      }
    }
  },
  {
    deep: true,
  }
);
</script>
<style lang="less">
.app-template {
  width: 100%;
  height: 100%;
}
</style>
