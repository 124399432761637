import type { IDataSourcesType } from "../interface/project";
import { CurrentRequest } from "fusion-apis";
import { getParamsValue, dataSrcScheduler, runCustomJs } from "../utils";
import type { IDataSourcesParamsType } from "../interface/project";
import { ref,onBeforeUnmount } from "vue";

export function dataSourcesHooks(apps: any) {
  const projectStore = apps?.projectStore;
  const paramsStore = apps?.paramsStore;
  const genStore = apps?.genStore; //衍生运行时数据池
  const globalEmitter = window['PAGEEDITOR']["globalEmitter"];

  const dataScheduler = dataSrcScheduler.getInstance();

  const times = ref();

  // 更新组件数据
  const updateElementData = (id: string, pageId: string, isEdit?: boolean, callBack?:any) => {
    const datasourcesPool = projectStore.getCurrentPageById(pageId).dataSources;
    if (apps?.compInstance[pageId][id] && Object.keys(datasourcesPool).length > 0) {
      const elementInstance = apps.compInstance[pageId][id];
      const dataSourceInfo = datasourcesPool[id] as IDataSourcesType;
      if (
        dataSourceInfo?.definiteMethod == "config" &&
        dataSourceInfo?.url &&
        elementInstance["updateData"]
      ) {
        // 如果是定时的，则进行定时请求
        if (dataSourceInfo.isInterval) {
           // 调用接口
           isEdit ? getData(dataSourceInfo, id, pageId,callBack) : callDataSource(dataSourceInfo, id, pageId,callBack);
          times.value = setInterval(() => {
            // 调用接口
            isEdit ? getData(dataSourceInfo, id, pageId,callBack) : callDataSource(dataSourceInfo, id, pageId,callBack);
          }, Number(dataSourceInfo.intervalTime) * 1000);
        } else {
          // 调用接口
          isEdit ? getData(dataSourceInfo, id, pageId,callBack) : callDataSource(dataSourceInfo, id, pageId,callBack);
        }

        // 如果是自定义数据源
      } else if (
        dataSourceInfo?.definiteMethod == "customize" &&
        dataSourceInfo?.value &&
        elementInstance["updateData"]
      ) {
        elementInstance["updateData"](
          JSON.parse(dataSourceInfo.value),
          dataSourceInfo
        );
        loadReady(callBack); //标记当前组件数据源已加载完毕
      } else if (
        dataSourceInfo?.definiteMethod == "customJs" &&
        dataSourceInfo?.customOption &&
        elementInstance["updateData"]
      ) {
        const res = runCustomizeJs(dataSourceInfo.customOption);
        elementInstance["updateData"](res, dataSourceInfo);
        loadReady(callBack); //标记当前组件数据源已加载完毕
      } else {
        //无数据源
        loadReady(callBack); //标记当前组件数据源已加载完毕
      }
    } else {
      //无数据源
      loadReady(callBack); //标记当前组件数据源已加载完毕
    }
  };

  /**
   * 脚本执行方法
   * @function
   * @author zhangsy 2023年05月03日 10:48:09
   * @param {*} param
   * @return {*} param
   * */
  function runCustomizeJs(customEvent,data?) {
    const { methodCode, params, } = customEvent;

    if (methodCode) {
      const func = projectStore.getCustomJSByCode(methodCode);
      if (func) {
        const param = getParams(params, "name", "value");
        return runCustomJs(param, func, data);
      }
    }
  }

  function getData(dataSourceInfo: any, id: string, pageId: string,callBack?:any) {
    paramsStore.setCurrentPageId(pageId);

      // 获取调用接口的参数
      let urlParam = getParamInDataSource(dataSourceInfo);
      let releasedRequestInstance;

      const requestList = (window as any)['PAGEEDITOR']?.requestList;

      // 如果包含/atgis则使用第三方接口
      if (dataSourceInfo.url.startsWith("/atgis")) {
        releasedRequestInstance = requestList.thirdUrlRequestInstance || CurrentRequest.getInstance(
          (window as any).deployConfig.thirdUrl,
          {}
        );
      } else if (dataSourceInfo.code && dataSourceInfo.code.startsWith("ws")) {
        releasedRequestInstance = requestList.BaseRequestInstance || CurrentRequest.getInstance(
          (window as any).deployConfig.baseUrl,
          {}
        );
      } else {
        releasedRequestInstance = requestList.MetaRequestInstance || CurrentRequest.getInstance(
          (window as any).deployConfig.metaUrl,
          {}
        );
      }

      const url = dataSourceInfo.dataSourceCode
        ? `${dataSourceInfo.url}/${dataSourceInfo.dataSourceCode}`
        : `${dataSourceInfo.url}`;
      releasedRequestInstance[dataSourceInfo.requestMethod.toLowerCase()](
        url,
        urlParam,
        {
          "Content-Type": dataSourceInfo.contentType || "application/json",
        }
      )
        .then(async (res) => {
          const elementInstance = apps.compInstance[pageId][id];
          if (elementInstance["updateData"]) {
            let result = res.data;
            if (dataSourceInfo.isTransform && dataSourceInfo.customOption) {
              result = runCustomizeJs(dataSourceInfo.customOption,res.data);
            }
            await elementInstance["updateData"](result, dataSourceInfo);
            dataScheduler.dynamicDataSrcCb(id);
            loadReady(callBack); //标记当前组件数据源已加载完毕
          }
        })
        .catch(error => {
          const elementInstance = apps.compInstance[pageId][id];
          if (elementInstance["updateData"]){
            elementInstance["updateData"](null, dataSourceInfo);
          }
          console.error(error);
          loadReady(callBack); //标记当前组件数据源已加载完毕
        });
  }

  /**
   * 调用接口
   * @author zhangsy 2023年12月06日 10:47:08
   * @dataSourceInfo 数据源信息
   * @id 触发数据源的组件id
   * @return param
   * */
  function callDataSource(dataSourceInfo: any, id: string, pageId: string,callBack?:any) {
    const dataSourceArr = projectStore.getCurrentPageById(pageId)
      .dataSources as IDataSourcesType[];
    dataScheduler.addDataSrcNode(dataSourceInfo, dataSourceArr, id, () => {
      getData(dataSourceInfo, id, pageId,callBack);

      // paramsStore.setCurrentPageId(pageId);

      // // 获取调用接口的参数
      // let urlParam = getParamInDataSource(dataSourceInfo);
      // let releasedRequestInstance;

      // const requestList = (window as any)['PAGEEDITOR']?.requestList;

      // // 如果包含/atgis则使用第三方接口
      // if (dataSourceInfo.url.startsWith("/atgis")) {
      //   releasedRequestInstance = requestList.thirdUrlRequestInstance || CurrentRequest.getInstance(
      //     (window as any).deployConfig.thirdUrl,
      //     {}
      //   );
      // } else {
      //   releasedRequestInstance = requestList.ReleasedRequestInstance || CurrentRequest.getInstance(
      //     (window as any).deployConfig.releasedUrl,
      //     {}
      //   );
      // }

      // const url = dataSourceInfo.dataSourceCode
      //   ? `${dataSourceInfo.url}/${dataSourceInfo.dataSourceCode}`
      //   : `${dataSourceInfo.url}`;
      // releasedRequestInstance[dataSourceInfo.requestMethod.toLowerCase()](
      //   url,
      //   urlParam,
      //   {
      //     "Content-Type": dataSourceInfo.contentType || "application/json",
      //   }
      // )
      //   .then(async (res) => {
      //     const elementInstance = apps.compInstance[pageId][id];
      //     if (elementInstance["updateData"]) {
      //       let result = res.data;
      //       if (dataSourceInfo.isTransform && dataSourceInfo.customOption) {
      //         result = runCustomizeJs(dataSourceInfo.customOption,res.data);
      //       }
      //       await elementInstance["updateData"](result, dataSourceInfo);
      //       dataScheduler.dynamicDataSrcCb(id);
      //       loadReady(); //标记当前组件数据源已加载完毕
      //     }
      //   })
      //   .catch(() => {
      //     loadReady(); //标记当前组件数据源已加载完毕
      //   });
    });
  }

  /**
   * 获取该数据源请求的参数
   * @author zhangsy 2023年12月06日 10:46:11
   * @dataSourceInfo 数据源
   * @return param
   * */
  function getParamInDataSource(dataSourceInfo: IDataSourcesType) {
    let params = {};
    dataSourceInfo.params &&
      dataSourceInfo.params.forEach((paramInfo: IDataSourcesParamsType) => {
        params[paramInfo.name] = getParamsValue(paramsStore, paramInfo.value);
      });
    return params;
  }

//获取事件参数
  function getParams(paramArr: any[], key: string, val: string): object {
    let param = {};
    paramArr &&
      paramArr.forEach((v) => {
        param[v[key]] = getParamsValue(paramsStore, v[val]);
      });
    return param;
  }

  //组件加载完毕处理
  function loadReady(callBack?:any): void {
    genStore && genStore.reduceLoadingCount();
    if (callBack) callBack();
  }

  onBeforeUnmount(() => {
    if (times.value) {
      clearInterval(times.value);
    }
  });

  // 监听updateElementData
  globalEmitter.off("updateElementData", updateElementData); //防止总线冒泡
  globalEmitter.on("updateElementData", updateElementData);
  return {
    getParamInDataSource,
  };
}
