import { guid } from "../../utils/utils";
import { IConditionType } from "../helper";
import type { IElementMethodParam } from "./projectParam";
export interface IEventsMethods {

  // 名称
  label: string;

  // key名
  key: string;

  // 提示信息
  annotation?: string;

  // 参数
  params?: IElementMethodParam[];

  //第三方页面容器所需方法参数，例如绘图、报表等
  graphOption?: any;
}

export interface IEventsEvents {

  // 名称
  label: string;

  // key名
  key: string;

  // 提示信息
  annotation?: string;

  //参数
  params?:any[]
}

export interface IEventsParams {

  // 名称
  label: string;

  // key名
  key: string;

  // 值
  value: any;

  // 提示信息
  annotation?: string;

  // 获取完毕判断
  state: boolean;

  //标记参数是否为动态参数
  valType?: "dynamic" | "static";
}

//组件参数
export type IEventsEleParams = Record<string, IEventsParams[]>;

export interface IElementEvents {

  // 可触发事件
  methods?: IEventsMethods[];

  // 可触发元素
  events?: IEventsEvents[];

  // 抛出参数
  params?: IEventsParams[];
}
export interface IElementBaseStyle {
  left?: number;
  top?: number;
  width?: number;
  height?: number;
  zIndex?: number;
  show?: boolean;
}

//组件基本属性接口
export interface IElementBaseAttrType {

  // 高度
  height: number | string;

  // 宽度
  width: number | string;

  // 到顶部距离
  top: number;

  // 到左侧距离
  left: number;

  // 层级
  zIndex?: number;

  // 是否可见
  isShow?: boolean;

  // 是否开启条件
  isCondition?: boolean;

  // 是否条件可见
  isShowCondition?: boolean;

  // 条件判断
  conditions?: IConditionType[];

  // 切换宽高单位
  changeUnit?: boolean;
}

/** 动画元素 */
export interface IElementInitValue {
  baseAttr: IElementBaseAttrType;
  extendAttr?: Record<string, any>;
  animationAttr?: IAnimationGroup;
}

/** 动画属性 */
export interface IAnimationElement {

  /** 延迟时间 */
  delay?: number;

  /** 持续时间 */
  duration?: number;

  /** 无限循环 */
  infinite?: boolean;

  /** 动画次数 */
  interationCount?: number;

  /** 动画名称 */
  label: string;

  /** 动画值 */
  name: string;
}

/** 动画组属性 */
export interface IAnimationGroup {
  [animations: string]: IAnimationElement[];

  /** 进入动画组 */
  // inAnimations: IAnimationElement[]

  /** 强调动画组 */
  // stressAnimations: IAnimationElement[]

  // /** 退出动画组 */
  // outAnimations: IAnimationElement[]
}
export interface IElement {
  id: string;

  code: string;

  /** 数据源名称 */
  formatCode: string;

  /** 名称 */
  name: string;

  // 中文名称
  title: string;

  /** 元素ID */
  mId: number;

  /** 物料元素版本 */
  mVersion: string;

  /** 元素基本样式 */
  style: IElementBaseStyle;

  /** 元素的属性 */
  props: IElementInitValue;

  /** 属性初始值 */
  initValue: IElementInitValue;

  /** 事件 */
  events: IElementEvents;

  /** 若是容器組件，则有子节点，子节点多一个slotName键 */
  children: IElement[];

  isReady: boolean;

  pid: string;

  /** 标记是容器还是普通组件 */
  kind: string;

  /** 标记是否事运行时组件 */
  visibility: number;

  /** 是否存在数据源 */
  dataSourceExists: 0 | 1;

  /** 标记在组件列表中是否展示删除组件 */
  showDeleteIcon?: boolean;

  /** 文件名称 */
  fileName: string;
}

export interface IElementFilter {
  id: string;

  /** 名称 */
  name: string;

  /** 若是容器組件，则有子节点，子节点多一个slotName键 */
  children?: IElement[];
}

export class PageElement implements IElement {
  public static create(e?: IElement) {
    const element = new PageElement();
    if (e) {
      element.id = e.id;
      element.name = e.name;
      element.title = e.title;
      element.code = e.code;
      element.formatCode = e.formatCode;
      element.mId = e.mId;
      element.mVersion = e.mVersion;
      element.dataSourceExists = e.dataSourceExists;
      element.style = e.style;
      element.props = e.props;
      element.events = e.events;
      element.initValue = e.initValue;
      element.children = e.children;
      element.isReady = e.isReady;
      element.pid = e.pid;
      element.kind = e.kind;
      element.visibility = e.visibility;
      element.fileName = e.fileName;
    }
    return element;
  }

  public id: string = guid();

  //TODO 临时名称
  public name = "New Element";

  public title = "临时组件";

  public code = "New Element";

  public formatCode: string;

  public isReady = false;

  public visibility = 1;

  public dataSourceExists: 0 | 1;

  public mId: number;

  public mVersion: string;

  public style: IElementBaseStyle = {};

  public props: IElementInitValue = {
    baseAttr: { left: 0, top: 0, width: 0, height: 0 },
  };

  public initValue: IElementInitValue = {
    baseAttr: { left: 0, top: 0, width: 0, height: 0 },
    extendAttr: {},
    animationAttr: {
      inAnimations: [],
      stressAnimations: [],
      outAnimations: [],
    },
  };

  public events: IElementEvents = {};

  public children: IElement[] = [];

  public pid: string;

  public kind: string;

  public fileName: string;

  // 清除内部子元素
  public clearChildren() {
    this.children.length = 0;
  }

  public getRecord(): IElement {
    return {
      id: this.id,
      name: this.name,
      title: this.title,
      code: this.code,
      formatCode: this.formatCode,
      mId: this.mId,
      mVersion: this.mVersion,
      style: this.style,
      props: this.props,
      initValue: this.initValue,
      events: this.events,
      children: this.children,
      isReady: this.isReady,
      pid: this.pid,
      kind: this.kind,
      dataSourceExists: this.dataSourceExists,
      visibility: this.visibility,
      fileName: this.fileName,
    };
  }
}
